import { Link } from 'gatsby'
import { useRef, useState } from 'react'
import type { Level1 } from 'src/graphql/queries/menu'
import Button from 'src/components/ui/Button'
import { Image } from 'src/components/ui/Image'
import { stripUrl } from 'src/utils/stripUrl'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import { urlToImage } from '../Menu/DropdownMenu'
import { StaticItemSecondLevel } from './components/StaticItemSecondLevel'
import { NavItemSecondLevel } from './components/NavItemSecondLevel'
import { ContentTitleNavFirstLevel } from './components/ContentTitleNav'
import { TitleSubmenu } from './components/TitleSubmenu'
import { ChevronBlue } from './components/utils/icons'

export function NavItem({
  item,
  setMenuIsOpen,
}: {
  item: Level1
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [openNavigation, setOpenNavigation] = useState(false)
  const [delayToClose, setDelayToClose] = useState(false)
  const submenuRef = useRef<HTMLDivElement>(null)

  const handleOpenNavigation = () => {
    setOpenNavigation(true)
    setDelayToClose(true)
  }

  const handleCloseNavigation = () => {
    setOpenNavigation(false)

    setTimeout(() => {
      setDelayToClose(false)
    }, 4000)
  }

  const handleSendEvent = (position: string, event: string | undefined) => {
    const eventTrack = makeEventTrack({
      eventAction: `${event}${position}`,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
  }

  return (
    <li>
      {item.categories?.length ? (
        <Button
          type="button"
          aria-label="abrir navcontent"
          className="text-left w-full !p-0 mb-[24px]"
          onClick={() => {
            handleOpenNavigation()
          }}
        >
          <ContentTitleNavFirstLevel title={item.title ?? ''} />
        </Button>
      ) : (
        <Link
          className="text-left w-full !p-0 mb-[24px]"
          to={item.url ?? ''}
          target={item?.new_tab ? '_blank' : '_top'}
          aria-label={item.title}
          rel="noreferrer"
          onClick={() => {
            handleOpenNavigation()
          }}
        >
          <ContentTitleNavFirstLevel title={item.title ?? ''} />
        </Link>
      )}
      {(openNavigation || delayToClose) && (
        <div
          className={`nav-content fixed h-full top-0 left-0 w-full z-[1] bg-white pb-[57px] ${
            openNavigation ? `entrance-animation ` : `exit-animation`
          }`}
        >
          <div
            ref={submenuRef}
            className="flex flex-col h-full w-full overflow-y-auto overflow-x-hidden scrollbar-v2"
          >
            <TitleSubmenu
              title={item.title ?? ''}
              actionClose={handleCloseNavigation}
              setMenuIsOpen={setMenuIsOpen}
            />
            <ul className="flex flex-col px-7 bg-white">
              {item.categories?.map((subItem, index) => {
                return item.title === 'Navegue por Esportes' ? (
                  <NavItemSecondLevel
                    key={`${subItem}-${index}-mob`}
                    department={item.title ?? ''}
                    item={subItem}
                    setMenuIsOpen={setMenuIsOpen}
                  />
                ) : (
                  <StaticItemSecondLevel
                    key={`${subItem}-${index}-mob`}
                    department={item.title ?? ''}
                    item={subItem}
                    setMenuIsOpen={setMenuIsOpen}
                  />
                )
              })}
            </ul>
            {!item.imageBannerMobile.includes('empty') && (
              <article>
                <a
                  href={item.linkBanner}
                  target={item.new_tabBanner ? '_blank' : '_top'}
                  rel="noreferrer"
                >
                  <Image
                    src={stripUrl(urlToImage(item.imageBannerMobile))}
                    alt={`Banner Menu - ${item.title}-Mob`}
                    width={337}
                    height={190}
                    loading="lazy"
                    className="w-full h-auto max-w-[337px] mx-auto my-6"
                  />
                </a>
              </article>
            )}

            {item.url && item.url !== '/#' && (
              <div className="fixed bottom-0 w-full flex items-center gap-2 py-4 px-7 border-t-[1px] border-solid border-restructure-border-primary mt-[9px]">
                <Link
                  className="block mobile-body-semibold-text3 desktop-body-semibold-text3 text-restructure-action"
                  to={item.url ?? ''}
                  aria-label={item.title ?? ''}
                  rel="noreferrer"
                  onClick={() => {
                    handleSendEvent(' - Ver tudo - Menu', item.title)
                    setMenuIsOpen(false)
                  }}
                >
                  Ver tudo em {item.title}
                </Link>
                <ChevronBlue />
              </div>
            )}
          </div>
        </div>
      )}
    </li>
  )
}
