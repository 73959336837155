import type { EventTrack, EventTrackParams } from './types/eventTrack'

export const makeEventTrack = ({
  eventAction,
  eventPage,
  eventCategory = 'clique',
}: EventTrackParams): EventTrack => {
  return {
    event: 'event_track',
    event_category: eventCategory,
    event_action: eventAction,
    event_label: eventPage,
  }
}
