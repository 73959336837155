import axios from 'axios'
import nProgress from 'nprogress'

import { OrderForm } from '../../entity/orderForm'

export const addCartCoupon = async (orderFormId: string, coupon: string) => {
  nProgress.start()

  const { data } = await axios.post(
    `/api/checkout/orderForm/${orderFormId}/coupons`,
    {
      text: coupon,
    }
  )

  nProgress.done()

  return new OrderForm(data)
}
