export const kidsMenuData = {
  title: 'Infantil',
  url: '/#',
  typeDestinySectionLevel01: 'abrir-listagem',
  UriSectionLevel01: '',
  imageBannerDesktop: '/arquivos/image-empty-280x360.png',
  imageBannerMobile: '/arquivos/image-empty-337x190.png',
  linkBanner: '/#',
  typeDestinyBannerLevel01: 'abrir-listagem',
  UriBannerLevel01: '',
  iconAPP:
    'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/6ce313ce-aad4-4738-9474-cdd12a32a085___17a46fbf406d10e627f43783d20fd0c9.png',
  categories: [
    {
      title: 'Produtos mais vendidos',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Mais Vendidos',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/9b88e9ff-a791-42b5-929b-81ed2090f922___b0825cdbe546cf43799d83f01c0eab62.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Bicicletas',
          url: '/pesquisa/?q=Bicicletas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371737/&filter=d:genero:infantil',
        },
        {
          title: 'Patinetes',
          url: '/pesquisa/?q=Patinetes infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371754/',
        },
        {
          title: 'Bermudas e shorts',
          url: '/pesquisa/?q=Bermudas e shorts infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371518/&filter=d:genero:infantil',
        },
        {
          title: 'Mochilas, bolsas e malas',
          url: '/pesquisa/?q=Mochilas bolsas e malas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371677/&filter=d:genero:infantil',
        },
        {
          title: 'Chuteiras',
          url: '/pesquisa/?q=Chuteiras infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371536/&filter=d:genero:infantil',
        },
        {
          title: 'Skates',
          url: '/pesquisa/?q=Skates infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371723/&filter=d:genero:infantil',
        },
        {
          title: 'Agasalhos e fleeces',
          url: '/pesquisa/?q=Agasalhos e fleeces infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371507/&filter=d:genero:infantil',
        },
        {
          title: 'Calças e leggings',
          url: '/pesquisa/?q=Calças e leggings infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371517/&filter=d:genero:infantil',
        },
        {
          title: 'Saias e tules',
          url: '/pesquisa/?q=Saias e tules infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371515/&filter=d:genero:infantil',
        },
        {
          title: 'Bolas',
          url: '/pesquisa/?q=Bolas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371747/',
        },
      ],
    },
    {
      title: 'Novidades',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Novidades',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/1d17964e-ebd2-4387-957a-b7c78f3ab1b8___182d7be3da7caa20c05532d80aeb2399.png',
      typeThirtLevel: '3.5 Listagem simples',
    },
    {
      title: 'Roupas',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Roupa',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/453de7d8-3f65-4c55-acd8-a7cad2f6b057___72a638d470694a2a0fddc5bef601da39.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Camisetas',
          url: '/pesquisa/?q=Camisetas infantil',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371495/&filter=d:genero:infantil',
        },
        {
          title: 'Agasalhos e fleeces',
          url: '/pesquisa/?q=Agasalhos e fleeces infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371507/&filter=d:genero:infantil',
        },
        {
          title: 'Capas de chuva',
          url: '/pesquisa/?q=Capas de chuva infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371501/&filter=d:genero:infantil',
        },
        {
          title: 'Roupões',
          url: '/pesquisa/?q=Roupões infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371504/&filter=d:genero:infantil',
        },
        {
          title: 'Sungas',
          url: '/pesquisa/?q=Sungas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371489/72371511/&filter=d:genero:infantil',
        },
        {
          title: 'Saias e tules',
          url: '/pesquisa/?q=Saias e tules infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371515/&filter=d:genero:infantil',
        },
        {
          title: 'Biquínis',
          url: '/pesquisa/?q=Biquínis infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371489/72371512/&filter=d:genero:infantil',
        },
        {
          title: 'Bermuda e shorts',
          url: '/pesquisa/?q=Bermuda e shorts infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371518/&filter=d:genero:infantil',
        },
        {
          title: 'Kimonos',
          url: '/pesquisa/?q=Kimonos infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371491/72371519/&filter=d:genero:infantil',
        },
        {
          title: 'Todas roupas infantis',
          url: '/pesquisa/?q=roupas infantis',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/&filter=d:genero:infantil',
        },
      ],
    },
    {
      title: 'Calçados',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Calçados',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/0206491b-a1e1-4946-80b8-456695af9970___356faf0369b96c41bf072660d5d0ad1e.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Botas',
          url: '/pesquisa/?q=botas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371532/&filter=d:genero:infantil',
        },
        {
          title: 'Chinelos',
          url: '/pesquisa/?q=chinelos infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371533/&filter=d:genero:infantil',
        },
        {
          title: 'Botinas',
          url: '/pesquisa/?q=Botinas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371535/&filter=d:genero:infantil',
        },
        {
          title: 'Chuteiras',
          url: '/pesquisa/?q=Chuteiras infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371536/&filter=d:genero:infantil',
        },
        {
          title: 'Sandálias',
          url: '/pesquisa/?q=Sandálias infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371537/&filter=d:genero:infantil',
        },
        {
          title: 'Sapatilhas',
          url: '/pesquisa/?q=Sapatilhas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371538/&filter=d:genero:infantil',
        },
        {
          title: 'Tênis',
          url: '/pesquisa/?q=Tênis infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371539/&filter=d:genero:infantil',
        },
        {
          title: 'Meias',
          url: '/pesquisa/?q=Meias infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371530/72371530/&filter=d:genero:infantil',
        },
        {
          title: 'Meiões',
          url: '/pesquisa/?q=Meiões infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371530/72371542/&filter=d:genero:infantil',
        },
        {
          title: 'Todos calçados infantis',
          url: '/pesquisa/?q=calçados infantis',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/&filter=d:genero:infantil',
        },
      ],
    },
    {
      title: 'Acessórios',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Novidades',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/3150b0d1-fcbe-441b-8c99-1cf774264c1f___d61c2ebf14bdc8330700c2563ef4cd12.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Boné, chapeu e viseiras',
          url: '/pesquisa/?q=Boné, chapeu e viseiras infantis',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371609/&filter=d:genero:infantil',
        },
        {
          title: 'Mochilas, bolsas e malas',
          url: '/pesquisa/?q=Mochilas bolsas e malas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371677/&filter=d:genero:infantil',
        },
        {
          title: 'Kits de proteção',
          url: '/pesquisa/?q=Kits de proteção infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371627/&filter=d:genero:infantil',
        },
        {
          title: 'Cadeirinhas de bebê',
          url: '/pesquisa/?q=Cadeirinhas de bebê',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371549/72371556/&filter=d:genero:infantil',
        },
        {
          title: 'Touca',
          url: '/pesquisa/?q=Touca infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371615/&filter=d:genero:infantil',
        },
        {
          title: 'Toalhas',
          url: '/pesquisa/?q=Toalhas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371689/&filter=d:genero:infantil',
        },
        {
          title: 'Capacetes',
          url: '/pesquisa/?q=Capacetes infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371629/&filter=d:genero:infantil',
        },
        {
          title: 'Luvas',
          url: '/pesquisa/?q=Luvas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371619/&filter=d:genero:infantil',
        },
        {
          title: 'Lancheiras',
          url: '/pesquisa/?q=Lancheiras infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371654/&filter=d:genero:infantil',
        },
        {
          title: 'Todos acessórios infantis',
          url: '/pesquisa/?q=acessórios infantis',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/&filter=d:genero:infantil',
        },
      ],
    },
    {
      title: 'Iniciação',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Kit iniciação',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/75171620-24c0-4879-a91a-0f68fe78c1b4___a97effc7cb64f0f0c3586643fd280a38.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Kits de proteção ',
          url: '/pesquisa/?q=Kits de proteção infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371627/&filter=d:genero:infantil',
        },
        {
          title: 'Pranchas',
          url: '/pesquisa/?q=Pranchas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371744/&filter=d:genero:infantil',
        },
        {
          title: 'Boias e espaguetes',
          url: '/pesquisa/?q=Boias e espaguetes infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371697/&filter=d:genero:infantil',
        },
        {
          title: 'Bicicleta de equilibrio',
          url: '/pesquisa/?q=Bicicleta de equilibrio infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371737/&filter=d:genero:infantil',
        },
        {
          title: 'Rodinhas',
          url: '/pesquisa/?q=Rodinhas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371551/72371549/72371579/',
        },
        {
          title: 'Faixa de graduação',
          url: '/pesquisa/?q=Faixa de graduação infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371683/',
        },
        {
          title: 'Touca',
          url: '/pesquisa/?q=Touca infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371615/&filter=d:genero:infantil',
        },
        {
          title: 'Óculos de natação',
          url: '/pesquisa/?q=Óculos de natação infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371613/&filter=d:genero:infantil',
        },
        {
          title: 'Collant',
          url: '/pesquisa/?q=Collant infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371491/72371521/',
        },
        {
          title: 'Chuteiras',
          url: '/pesquisa/?q=Chuteiras infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371536/&filter=d:genero:infantil',
        },
      ],
    },
    {
      title: 'Para brincar',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Para Brincar',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/0efea272-8295-46d6-b3d2-251e213f31ce___f51a50010ff472eedc4a20fe83cb890a.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Jogos de imersão',
          url: '/pesquisa/?q=Jogos de imersão infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371657/&filter=d:genero:infantil',
        },
        {
          title: 'Kits e conjuntos',
          url: '/pesquisa/?q=Kits e conjuntos infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371657/&filter=d:genero:infantil',
        },
        {
          title: 'Peteca',
          url: '/pesquisa/?q=Peteca infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371725/&filter=d:genero:infantil',
        },
        {
          title: 'Cordas',
          url: '/pesquisa/?q=Cordas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371745/&filter=d:genero:infantil',
        },
        {
          title: 'Frisbie',
          url: '/pesquisa/?q=Frisbie',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371773/&filter=d:genero:infantil',
        },
        {
          title: 'Bambolês',
          url: '/pesquisa/?q=Bambolês infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371718/&filter=d:genero:infantil',
        },
        {
          title: 'Piscinas',
          url: '/pesquisa/?q=Piscinas infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371652/&filter=d:genero:infantil',
        },
        {
          title: 'Boias e espaguetes',
          url: '/pesquisa/?q=Boias e espaguetes infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371697/&filter=d:genero:infantil',
        },
        {
          title: 'Tacos',
          url: '/pesquisa/?q=Tacos infantil',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371761/&filter=d:genero:infantil',
        },
        {
          title: 'Trampolim',
          url: '/pesquisa/?q=Trampolim',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371748/&filter=d:genero:infantil',
        },
      ],
    },
  ],
  includeModule: true,
}
