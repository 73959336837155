export default function initializeServiceChatBot(pathName: string) {
  const pathsToInclude = ['/account', '/atendimento']

  if (!pathsToInclude.some((path) => pathName.includes(path))) {
    return
  }

  const chatIframe = document.querySelector(
    '.chatbot-iframe'
  ) as HTMLIFrameElement

  const isChatOpen = chatIframe?.style.display === 'block'

  if (isChatOpen) {
    return
  }

  const el = document.createElement('script')

  el.id = 'chatbot_script'
  el.type = 'text/javascript'
  el.src = 'https://bot.bluelab.com.br/chat/v2/react'
  el.setAttribute('company', 'decathlon')
  el.setAttribute('alias', 'decathlon')

  document.getElementById('chatbot_script')?.remove()
  document.body.appendChild(el)
}
