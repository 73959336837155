import axios from 'axios'

import { OrderForm } from '../../entity/orderForm'

export const clearMessages = async (orderFormId: string) => {
  const { data } = await axios.post(
    `/api/checkout/orderForm/${orderFormId}/clearMessages`
  )

  return new OrderForm(data)
}
