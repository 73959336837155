import axios from 'axios'

import { OrderForm } from '../../entity/orderForm'

export const addCartShippingData = async (orderFormId: string, cep: string) => {
  const { data } = await axios.post(
    `/api/checkout/orderForm/${orderFormId}/shippingData`,
    {
      cep,
    }
  )

  return new OrderForm(data)
}
