import type { Dispatch, SetStateAction } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import useWishlist from 'src/hooks/useWishlist'
import type { WishlistProduct } from 'src/typings/contexts/wishlist/wishlist-product'

import { useAccountContext } from '../account/context'

type WishlistProviderProps = {
  children: React.ReactNode
}

export type WishlistContextProps = {
  userWishlist: WishlistProduct[]
  setUserWishlist: Dispatch<SetStateAction<WishlistProduct[]>>
  wishlistId: string
  setWishlistId: Dispatch<SetStateAction<string>>
}

export const WishlistContext = createContext<WishlistContextProps>(
  {} as WishlistContextProps
)

export const WishlistProvider = ({ children }: WishlistProviderProps) => {
  const [userWishlist, setUserWishlist] = useState<WishlistProduct[]>([])
  const [wishlistId, setWishlistId] = useState<string>('')
  const { getWishlist } = useWishlist()
  const { isLoggedIn } = useAccountContext()

  useEffect(() => {
    getWishlist().then((wishlistData) => {
      if (!wishlistData) {
        return null
      }

      setUserWishlist(wishlistData?.data?.products ?? [])
      setWishlistId(wishlistData?.data?.id)

      return wishlistData?.data?.products
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  return (
    <WishlistContext.Provider
      value={{ userWishlist, setUserWishlist, wishlistId, setWishlistId }}
    >
      {children}
    </WishlistContext.Provider>
  )
}

export const useWishlistContext = () => useContext(WishlistContext)
