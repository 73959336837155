import { useMemo } from 'react'

import { formatCategoriesForEvent } from './formatted'

export interface ProductProps {
  id?: string
  name: string
  brand: string
  variant?: string
  price: string
  category?: string
  currencyCode?: string
  quantity?: number
  dimension1?: string
  dimension2?: string
  dimension12?: string
  dimension13?: string
  dimension25?: string
  position?: string
  sellerId?: string
  bestPrice?: number
  listPrice?: number
  arborescence?: string
}

export interface DataLayerProps {
  event: string
  ecommerce: {
    click: {
      actionField: {
        list: string
      }
      products: ProductProps[]
    }
  }
}

interface AddToCartDataLayerProps {
  event: string
  ecommerce: {
    currencyCode?: string
    remove?: {
      products: ProductProps[]
    }
    add?: {
      products: ProductProps[]
    }
  }
}

export const usePageViewEvent = () => {
  useMemo(() => {
    if (typeof window !== 'undefined' && typeof document === 'object') {
      const dataLayerObj = {
        event: 'pageView',
        location: window.location.href,
        page: `${document.location.pathname}`,
        referrer: '',
        title: '',
      }

      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push(dataLayerObj)

      return true
    }

    return false
  }, [])
}

export const useDepartmentViewEvent = () => {
  useMemo(() => {
    if (typeof window !== 'undefined' && typeof document === 'object') {
      const dataLayerObj = {
        event: 'departmentView',
        departmentId: undefined,
        departmentName: `${
          document.location.pathname.split('/').slice(1)[0] || ''
        }`,
        target: 1,
      }

      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push(dataLayerObj)

      return true
    }

    return false
  }, [])
}

export const useCategoryViewEvent = () => {
  useMemo(() => {
    if (typeof window !== 'undefined' && typeof document === 'object') {
      const dataLayerObj = {
        event: 'categoryView',
        categoryId: undefined,
        categoryName: `${
          document.location.pathname.split('/').slice(1)[1] || ''
        }`,
        target: 2,
      }

      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push(dataLayerObj)

      return true
    }

    return false
  }, [])
}

// eslint-disable-next-line
export const impressionEvent = (products: any[]) => {
  // eslint-disable-next-line
  const impressionObj = products.map((product: any, index: number) => {
    const category = formatCategoriesForEvent(product?.categories[0])
    const sport = product?.sport

    return {
      id: product?.productId,
      brand: product?.brand,
      list: document.title.split('|')[0].trim(),
      name: product?.productName.toLocaleLowerCase(),
      position: index + 1,
      category: `${sport}`,
      price: product?.priceRange?.sellingPrice?.lowPrice,
      dimension12: product?.items[0]?.sellers[0]?.sellerId,
      dimension13: product?.items[0]?.sellers[0]?.sellerName,
      arborescence: `${category}`,
    }
  })

  if (typeof window !== 'undefined' && typeof document === 'object') {
    const dataLayerObj = {
      event: 'viewListImpression',
      ecommerce: {
        currencyCode: 'BRL',
        impressions: impressionObj,
      },
    }

    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(dataLayerObj)

    return true
  }

  return false
}

export const useProductDetailEvent = ({
  id,
  name,
  brand,
  category,
  price,
  dimension12,
  dimension13,
  arborescence,
}: ProductProps) => {
  useMemo(() => {
    if (typeof window !== 'undefined' && typeof document === 'object') {
      const dataLayerObj = {
        event: 'productDetail',
        ecommerce: {
          detail: {
            products: [
              {
                id,
                name,
                brand,
                category,
                price,
                dimension12,
                dimension13,
                arborescence,
              },
            ],
          },
        },
      }

      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push(dataLayerObj)

      return true
    }

    return false

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, name, brand, category, price, dimension12, dimension13, arborescence])
}

const validateDataLayer = (dataEvent: string) => {
  let dataLayerObj
  const verifyEvent = window.dataLayer.filter((item) =>
    item?.event?.includes('experiment_pix')
  )

  if (!verifyEvent.length) {
    dataLayerObj = {
      event: dataEvent,
    }
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(dataLayerObj)
  } else {
    window.dataLayer.forEach((item, index) => {
      if (
        item.event === 'experiment_pix_true' ||
        item.event === 'experiment_pix_false'
      ) {
        window.dataLayer[index].event = dataEvent
      }
    })
  }
}

export const useProductEventPix = (
  bestPrice: number,
  listPrice: number,
  sellerId: string
) => {
  if (typeof window !== 'undefined' && typeof document === 'object') {
    if (sellerId !== '1') {
      validateDataLayer('experiment_pix_false')
    } else if (bestPrice >= listPrice) {
      validateDataLayer('experiment_pix_true')
    } else if (bestPrice < listPrice) {
      validateDataLayer('experiment_pix_false')
    }

    return true
  }

  return false
}

export const getObjectAddToCartClickEvent = ({
  id,
  currencyCode,
  name,
  category,
  brand,
  variant,
  price,
  quantity,
  dimension1,
  dimension2,
  dimension12,
  dimension13,
  arborescence,
}: ProductProps): AddToCartDataLayerProps | undefined => {
  if (typeof window !== 'undefined' && typeof document === 'object') {
    return {
      event: 'addToCart',
      ecommerce: {
        currencyCode,
        add: {
          products: [
            {
              id,
              name,
              category,
              brand,
              variant,
              price,
              quantity,
              dimension1,
              dimension2,
              dimension12,
              dimension13,
              arborescence,
            },
          ],
        },
      },
    }
  }

  return undefined
}

export const removeFromCartEvent = ({
  id,
  name,
  price,
  brand,
  category,
  variant,
  quantity,
  dimension1,
  dimension2,
  dimension12,
  dimension13,
}: ProductProps): AddToCartDataLayerProps | undefined => {
  if (typeof window !== 'undefined' && typeof document === 'object') {
    return {
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [
            {
              id,
              name,
              price,
              brand,
              category,
              variant,
              quantity,
              dimension1,
              dimension2,
              dimension12,
              dimension13,
            },
          ],
        },
      },
    }
  }

  return undefined
}

export const getObjectClickEvent = ({
  id,
  name,
  brand,
  category,
  price,
  position,
  dimension12,
  dimension13,
  arborescence,
}: ProductProps): DataLayerProps | undefined => {
  if (typeof window !== 'undefined' && typeof document === 'object') {
    return {
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: 'Search result' },
          products: [
            {
              id,
              name,
              brand,
              category,
              price,
              position,
              dimension12,
              dimension13,
              arborescence,
            },
          ],
        },
      },
    }
  }

  return undefined
}

export const handleClickProductEvent = (
  item: DataLayerProps | AddToCartDataLayerProps
): void => {
  try {
    if (item) {
      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push(item)
    }
  } catch {
    throw new Error('Object for dataLayer is undefined.')
  }
}
