export const highlightSports = {
  title: 'Esportes em destaque',
  url: '',
  typeDestinySectionLevel02: 'abrir-listagem',
  UriSectionLevel02: '',
  picto: 'Esportes em destaque',
  pictoAPP:
    'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/c0cb4279-d759-4c1d-afed-8a97a3b9b7a0___1555f1433b6d6a6bb140c880382e19c2.png',
  typeThirtLevel: '3.5 Listagem simples',
  subcategories: [
    {
      title: 'Corrida',
      url: 'https://www.decathlon.com.br/corrida',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:corrida',
    },
    {
      title: 'Trilha e Trekking',
      url: 'https://www.decathlon.com.br/trilha-e-trekking',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:trilha-e-trekking',
    },
    {
      title: 'Treino cardio',
      url: 'https://www.decathlon.com.br/treino-cardio',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:treino-cardio',
    },
    {
      title: 'Surf',
      url: 'https://www.decathlon.com.br/surf-e-bodyboard',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:surf-e-bodyboard',
    },
    {
      title: 'Beach Tênis',
      url: 'https://www.decathlon.com.br/beach-tennis',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:beach-tennis',
    },
    {
      title: 'Futebol',
      url: 'https://www.decathlon.com.br/futebol-de-campo',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:futebol',
    },
    {
      title: 'Basquete',
      url: 'https://www.decathlon.com.br/basquete',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:basquete',
    },
    {
      title: 'Natação',
      url: 'https://www.decathlon.com.br/natacao',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:natacao',
    },
    {
      title: 'Ciclismo',
      url: 'https://www.decathlon.com.br/ciclismo-estrada',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:grupo-de-esporte:ciclismo',
    },
    {
      title: 'Elétricos',
      url: 'https://www.decathlon.com.br/pesquisa/?q=ciclismo-eletrico',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:ciclismo-eletricos',
    },
  ],
  includeModule: true,
}
