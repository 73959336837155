import axios from 'axios'

export const logoutUser = async ({ pathname }: { pathname: string }) => {
  const { data: url } = await axios.post(
    `/api/account/logout?returnPath=${pathname}`
  )

  window.sessionStorage.removeItem('user-decathlon')
  window.location.href = url
}
