import { useAccountContext } from 'src/components/account/context'

import LoginDropdown from '../LoginDropdown/LoginDropdown'
import { ArrowRight, IconMyAccount } from './components/utils/icons'

type NavigationAccountProps = {
  handleMyAccountSubMenu: (state: boolean) => void
}

const NavigationAccount = ({
  handleMyAccountSubMenu,
}: NavigationAccountProps) => {
  const { userDecathlon, isLoggedIn } = useAccountContext()

  const userProfile = userDecathlon?.getUserProfile.claims

  return isLoggedIn ? (
    <button onClick={() => handleMyAccountSubMenu(true)} className="mb-[24px]">
      <div className="rounded-md px-3 py-4 flex items-center w-full h-full gap-[12px] bg-restructure-background-secondary">
        <div className="bg-restructure-background-primary rounded-round w-[40px] h-[40px] flex items-center justify-center">
          <IconMyAccount />
        </div>

        <div className="flex flex-col text-left">
          <span className="desktop-body-regular-text3 text-restructure-primary">
            {userProfile?.given_name &&
              `${userProfile?.given_name} ${userProfile?.family_name}`}
          </span>
          <small className="desktop-caption-regular text-restructure-secondary">
            Minha conta
          </small>
        </div>

        <div className="pl-3 ml-auto">
          <ArrowRight />
        </div>
      </div>
    </button>
  ) : (
    <LoginDropdown className="border border-[#E1E4E7] rounded-lg mb-8" />
  )
}

export default NavigationAccount
