export function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="mail">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 4.75C3.31421 4.75 2.75 5.31421 2.75 6V18C2.75 18.6858 3.31421 19.25 4 19.25H20C20.6858 19.25 21.25 18.6858 21.25 18V6C21.25 5.31421 20.6858 4.75 20 4.75H4ZM1.25 6C1.25 4.48579 2.48579 3.25 4 3.25H20C21.5142 3.25 22.75 4.48579 22.75 6V18C22.75 19.5142 21.5142 20.75 20 20.75H4C2.48579 20.75 1.25 19.5142 1.25 18V6Z"
          fill="#3643BA"
        />
        <path
          id="Vector (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.38558 5.56991C1.62312 5.23057 2.09076 5.14804 2.4301 5.38558L12 12.0845L21.5699 5.38558C21.9092 5.14804 22.3769 5.23057 22.6144 5.56991C22.852 5.90924 22.7694 6.37689 22.4301 6.61443L12.4301 13.6144C12.1719 13.7952 11.8281 13.7952 11.5699 13.6144L1.56991 6.61443C1.23057 6.37689 1.14804 5.90924 1.38558 5.56991Z"
          fill="#3643BA"
        />
      </g>
    </svg>
  )
}
