import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type { Product } from 'src/components/restructure/product/types/product'

import type { ProductClickEvent } from './types/productClickEvent'
import { makeProductShowcase } from './makeProductShowcase'
import handleUsablePageName from './common/handleUsablePageName'

export const makeProductClickEvent = (
  product: Product,
  productItem: ProductItem,
  complements: {
    listName?: string
    dimension29: string
  }
): ProductClickEvent => {
  const { listName, dimension29 } = complements

  const dimension24 = handleUsablePageName(window.location.pathname)

  return {
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: {
          list: listName ?? '',
          dimension24,
          dimension29,
        },
        products: [
          makeProductShowcase(product, productItem, dimension24, dimension29),
        ],
      },
      eventCallback: () => {
        document.location = `https://www.decathlon.com.br/${product.linkText}/p`
      },
    },
  }
}
