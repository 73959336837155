export const accessoriesMenuData = {
  title: 'Acessórios',
  url: '/#',
  typeDestinySectionLevel01: 'abrir-listagem',
  UriSectionLevel01: '',
  imageBannerDesktop: '/arquivos/image-empty-280x360.png',
  imageBannerMobile: '/arquivos/image-empty-337x190.png',
  linkBanner: '/#',
  typeDestinyBannerLevel01: 'abrir-listagem',
  UriBannerLevel01: '',
  iconAPP:
    'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/ad54ffe9-a6ba-4d15-a523-3c78cb8d3b90___918481713222908b2bfee374589f2278.png',
  categories: [
    {
      title: 'Produtos mais vendidos',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Mais Vendidos',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/8efc15a1-b51c-4c53-a229-c49ba0b96dc2___9af842c5ba3d30f58061a9605e6c87fa.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Mochilas, bolsas e malas',
          url: '/pesquisa/?q=Mochilas, bolsas e malas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371677',
        },
        {
          title: 'Toalhas',
          url: '/pesquisa/?q=Toalhas',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371689',
        },
        {
          title: 'Manutenção',
          url: '/pesquisa/?q=Manutenção',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: '',
        },
        {
          title: 'Boné, chapeu e viseiras',
          url: '/pesquisa/?q=Boné, chapeu e viseiras',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371609/',
        },
        {
          title: 'Relógios esportivos',
          url: '/pesquisa/?q=Relógios esportivos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371623',
        },
        {
          title: 'Kits de proteção',
          url: '/pesquisa/?q=Kits de proteção',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371627',
        },
        {
          title: 'Whey Protein',
          url: '/pesquisa/?q=Whey Protein',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371548/72371602/',
        },
        {
          title: 'Rodas e rolamentos',
          url: '/pesquisa/?q=Rodas e rolamentos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371549/72371579/',
        },
      ],
    },
    {
      title: 'Novidades',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Novidades',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/b644c83c-e1a4-453d-b328-c18a69dd743e___431adba79d121e6b530d77b80acf745d.png',
      typeThirtLevel: '3.5 Listagem simples',
    },
    {
      title: 'Peças',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Todos os Esportes',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/b0778745-faef-45d8-b4cb-a659a22feed6___05c9c9b4cb08d358f2069232a842c05d.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Decks e quilhas',
          url: '/pesquisa/?q=Decks e quilhas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371549/72371572/',
        },
        {
          title: 'Suportes',
          url: '/pesquisa/?q=Suportes',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371549/72371567/',
        },
        {
          title: 'Rodas e rolamentos',
          url: '/pesquisa/?q=Rodas e rolamentos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371549/72371579/',
        },
        {
          title: 'Bagageiros e alforges',
          url: '/pesquisa/?q=Bagageiros e alforges',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371549/72371553/',
        },
        {
          title: 'Tudo em peças',
          url: '/pesquisa/?q=peças',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371549',
        },
      ],
    },
    {
      title: 'Vestuário',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Roupa',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/15ca0d7f-b5fd-4598-9ba7-cd90388e0085___974aa3667ade9b54cd817a34fd6c19e1.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Boné, chapeu e viseiras',
          url: '/pesquisa/?q=Boné, chapeu e viseiras',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371609/',
        },
        {
          title: 'Necessaire e pochetes',
          url: '/pesquisa/?q=Necessaire e pochetes',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371626/',
        },
        {
          title: 'Manguito e pernito',
          url: '/pesquisa/?q=Manguito e pernito',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371634/',
        },
        {
          title: 'Cintas e cintos',
          url: '/pesquisa/?q=Cintas e cintos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371628/',
        },
        {
          title: 'Tudo em vestuário',
          url: '/pesquisa/?q=vestuário',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/',
        },
      ],
    },
    {
      title: 'Manutenção',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Manutenção',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/c33065ba-1ead-4b08-abfc-b9770a321299___43818f8ce9f29cf19ec4c45dcec0a864.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Cola',
          url: '/pesquisa/?q=Cola',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371551/72371640/',
        },
        {
          title: 'Cuidado com couro',
          url: '/pesquisa/?q=Cuidado com couro',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371551/72371641/',
        },
        {
          title: 'Ferramentas',
          url: '/pesquisa/?q=Ferramentas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371551/72371642/',
        },
        {
          title: 'Manutenção e reparo',
          url: '/pesquisa/?q=Manutenção e reparo',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371551/72371645/',
        },
        {
          title: 'Tudo em manutenção',
          url: '/pesquisa/?q=manutenção',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371551/',
        },
      ],
    },
    {
      title: 'Apoio',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Apoio',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/8dc16368-e32c-40c9-95b0-f5ee23c90f75___a76d70039903e71fce7b25d2f93baaa2.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Raqueteiras',
          url: '/pesquisa/?q=Raqueteiras',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371700/',
        },
        {
          title: 'Bomba de ar',
          url: '/pesquisa/?q=Bomba de ar',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371670/',
        },
        {
          title: 'Iluminação e fonte de energia',
          url: '/pesquisa/?q=Iluminação e fonte de energia',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371664/',
        },
        {
          title: 'Colchões e colchonetes',
          url: '/pesquisa/?q=Colchões e colchonetes',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371672/',
        },
        {
          title: 'Tudo em apoio',
          url: '/pesquisa/?q=apoio',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/',
        },
      ],
    },
  ],
  includeModule: true,
}
