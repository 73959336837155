import classNames from 'classnames'

type Props = {
  label: string
  term?: string
  type: 'list' | 'suggestion'
  onClick: (term: string) => void
}
export function SuggestionItem({ term, label, type, onClick }: Props) {
  const labelFormat = term
    ? label.replace(
        term,
        `<strong data-testid="elementTerm" class="desktop-body-semibold-text3">${term}</strong>`
      )
    : label

  return (
    <button
      data-testid="linkList"
      className={classNames(
        'desktop-body-regular-text3 text-restructure-secondary whitespace-nowrap flex',
        {
          'bg-restructure-background-secondary border border-restructure-border-secondary rounded-round px-sm py-xs':
            type === 'suggestion',
        },
        {
          'hover:bg-restructure-background-secondary flex-1 py-xs px-md restructure-small-desktop:px-xl':
            type === 'list',
        }
      )}
      onClick={() => onClick(label)}
    >
      <p dangerouslySetInnerHTML={{ __html: labelFormat }} />
    </button>
  )
}
