export const salesMenuData = {
  title: 'Liquidação',
  url: '/pesquisa/?q=liquidacao',
  typeDestinySectionLevel01: 'abrir-listagem',
  UriSectionLevel01: '',
  imageBannerDesktop: '/arquivos/image-empty-280x360.png',
  imageBannerMobile: '/arquivos/image-empty-337x190.png',
  linkBanner: '/#',
  typeDestinyBannerLevel01: 'abrir-listagem',
  UriBannerLevel01: '',
  iconAPP:
    'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/1a0ecbd4-346f-4eb0-ab04-6d59ac089779___1db412457c081cea5ef575d51731df2b.png',
}
