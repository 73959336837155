import type { ProductItem, Product } from 'src/components/product/types'
import { formatCategoriesForEvent } from 'src/utils/formatted'

import type { Item as ItemEvent } from '../types/common/item'

type MakeItemProps = {
  product: Product
  productItem: ProductItem
  itemIndex: number
  listName: string
  listId: string
}

export const makeItem = ({
  product,
  productItem,
  itemIndex,
  listName,
  listId,
}: MakeItemProps): ItemEvent => {
  const { offer } = productItem
  const [sellerDefault] = productItem.sellers.filter(
    (seller) => seller.sellerDefault
  )

  return {
    item_name: productItem.name,
    item_id: product.productId, // id sku sap
    price: offer.price,
    item_brand: product.brand,
    item_category: sellerDefault.sellerId, // sellerID
    item_category2: formatCategoriesForEvent(product.categories[0]), // categories
    item_category3: product.productId, // productID vtex
    item_category4: productItem.itemId, // skuID vtex
    item_category5: '', // id modelo sap
    item_variant: productItem?.Cor?.[0],
    item_list_name: listName,
    item_list_id: listId,
    index: itemIndex,
    quantity: 1,
  }
}
