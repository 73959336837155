export const menMenuData = {
  title: 'Masculino',
  url: '/#',
  typeDestinySectionLevel01: 'abrir-listagem',
  UriSectionLevel01: '',
  imageBannerDesktop: '/arquivos/image-empty-280x360.png',
  imageBannerMobile: '/arquivos/image-empty-337x190.png',
  linkBanner: '/#',
  typeDestinyBannerLevel01: 'abrir-listagem',
  UriBannerLevel01: '',
  iconAPP:
    'https://decathlonstore.vteximg.com.br/arquivos/default-campo-03.png',
  categories: [
    {
      title: 'Produtos Mais Vendidos',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Mais Vendidos',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/6038e4a0-d71a-4146-81c5-f697ed61a26c___3106669394c72187bcf16567471d2991.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Calças e leggings',
          url: '/pesquisa/?q=Calças masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371517/&filter=d:genero:masculino',
        },
        {
          title: 'Camisetas',
          url: '/pesquisa/?q=Camisetas masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371495/&filter=d:genero:masculino',
        },
        {
          title: 'Agasalhos e fleeces',
          url: '/pesquisa/?q=Agasalhos e fleeces masculinos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371507/&filter=d:genero:masculino',
        },
        {
          title: 'Tênis',
          url: '/pesquisa/?q=Tênis masculino',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371539/&filter=d:genero:masculino',
        },
        {
          title: 'Botas',
          url: '/pesquisa/?q=Botas masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371532/&filter=d:genero:masculino',
        },
        {
          title: 'Mochilas, bolsas e malas',
          url: '/pesquisa/?q=Mochilas bolsas e malas masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371677/&filter=d:genero:masculino',
        },
        {
          title: 'Chuteiras',
          url: '/pesquisa/?q=Chuteiras',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371536/&filter=d:genero:masculino',
        },
        {
          title: 'Bermudas e shorts',
          url: '/pesquisa/?q=Bermudas e shorts masculinos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371518/&filter=d:genero:masculino',
        },
        {
          title: 'Chinelos',
          url: '/pesquisa/?q=Chinelos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371533/&filter=d:genero:masculino',
        },
        {
          title: 'Boné, chapéu e viseira ',
          url: '/pesquisa/?q=Boné, chapéu e viseira masculina',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371609/&filter=d:genero:masculino',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Roupas',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Roupa',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/5d5c885d-95dd-43c5-a054-8ab31f5b5336___4c823d967467bc812294f9a9b7323d63.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Regatas',
          url: '/pesquisa/?q=Regatas masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371493/&filter=d:genero:masculino',
        },
        {
          title: 'Camisetas',
          url: '/pesquisa/?q=Camisetas masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371495/&filter=d:genero:masculino',
        },
        {
          title: 'Jaquetas',
          url: '/pesquisa/?q=Jaquetas masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371499/&filter=d:genero:masculino',
        },
        {
          title: 'Agasalho e fleeces',
          url: '/pesquisa/?q=Agasalhos e fleeces masculinos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371507/&filter=d:genero:masculino',
        },
        {
          title: 'Sungas',
          url: '/pesquisa/?q=Sungas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371489/72371511/&filter=d:genero:masculino',
        },
        {
          title: 'Bermuda e shorts',
          url: '/pesquisa/?q=Bermudas e shorts masculinos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371518/&filter=d:genero:masculino',
        },
        {
          title: 'Calças e leggings',
          url: '/pesquisa/?q=Calças e leggings masculinos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371517/&filter=d:genero:masculino',
        },
        {
          title: 'Bretelles',
          url: '/pesquisa/?q=Bretelles',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371491/72371523/&filter=d:genero:masculino',
        },
        {
          title: 'Todas roupas masculinas',
          url: '/pesquisa/?q=Roupas masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/&filter=d:genero:masculino',
        },
      ],
    },
    {
      title: 'Calçados',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Calçados',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/359780c8-8023-400e-8f19-af4aa6edbc98___f2a862eed7fccff35b544fe00e16dfdc.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Botas',
          url: '/pesquisa/?q=Botas masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371532/&filter=d:genero:masculino',
        },
        {
          title: 'Chinelos',
          url: '/pesquisa/?q=Chinelos masculinos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371533/&filter=d:genero:masculino',
        },
        {
          title: 'Botinas',
          url: '/pesquisa/?q=Botinas masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371535/&filter=d:genero:masculino',
        },
        {
          title: 'Chuteiras',
          url: '/pesquisa/?q=Chuteiras masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371536/&filter=d:genero:masculino',
        },
        {
          title: 'Sandálias',
          url: '/pesquisa/?q=Sandálias masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371537/&filter=d:genero:masculino',
        },
        {
          title: 'Meiões',
          url: '/pesquisa/?q=Meiões',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371530/72371542/&filter=d:genero:masculino',
        },
        {
          title: 'Tênis',
          url: '/pesquisa/?q=Tênis masculino',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371539/&filter=d:genero:masculino',
        },
        {
          title: 'Meias',
          url: '/pesquisa/?q=Meias masculinas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371530/72371530/&filter=d:genero:masculino',
        },
        {
          title: 'Cadarços',
          url: '/pesquisa/?q=Cadarços',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371716/72371545/&filter=d:genero:masculino',
        },
        {
          title: 'Todos calçados masculinos',
          url: '/pesquisa/?q=calçados masculinos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/&filter=d:genero:masculino',
        },
      ],
    },
    {
      title: 'Acessórios',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Acessórios',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/46705557-2d61-4fda-bbb9-ec780c5dc38a___a1b121c77fa45699ea621826edd5f570.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Boné, chapeu e viseiras',
          url: '/pesquisa/?q=Boné, chapeu e viseiras',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371609/&filter=d:genero:masculino',
        },
        {
          title: 'Braçadeiras',
          url: '/pesquisa/?q=Braçadeiras',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371610/&filter=d:genero:masculino',
        },
        {
          title: 'Óculos',
          url: '/pesquisa/?q=Óculos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371613/&filter=d:genero:masculino',
        },
        {
          title: 'Luvas',
          url: '/pesquisa/?q=Luvas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371619/&filter=d:genero:masculino',
        },
        {
          title: 'Mochilas, bolsas e malas',
          url: '/pesquisa/?q=Mochilas bolsas e malas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371677/&filter=d:genero:masculino',
        },
        {
          title: 'Canivete e facas',
          url: '/pesquisa/?q=Canivete e facas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371674/&filter=d:genero:masculino',
        },
        {
          title: 'Kits e conjuntos',
          url: '/pesquisa/?q=Kits e conjuntos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371663/&filter=d:genero:masculino',
        },
        {
          title: 'Material e árbitro e técnico',
          url: '/pesquisa/?q=Material e árbitro e técnico',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371702/&filter=d:genero:masculino',
        },
        {
          title: 'Relógios esportivos',
          url: '/pesquisa/?q=Relógios esportivos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371623/&filter=d:genero:masculino',
        },
        {
          title: 'Todos acessórios masculinos',
          url: '/pesquisa/?q=acessórios masculinos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/&filter=d:genero:masculino',
        },
      ],
    },
  ],
  includeModule: true,
}
