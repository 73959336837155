import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import axios from 'axios'
import classNames from 'classnames'
import { LopOutline } from 'src/components/Icons/LopOutline'
import { CloseOutline } from 'src/components/Icons/CloseOutline'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import type { ParamsApi } from 'src/components/sections/ProductGalleryV2/ProductGallery'
import qs from 'query-string'
import { B2B } from 'src/store-config/B2B-Config'
import { B2C } from 'src/store-config/B2C-Config'
import { transformProductSuggestions } from 'src/utils/transformProductSuggestions'
import { useDebounce } from 'src/hooks/useDebounce'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import DOMPurify from 'dompurify'

import { ModalSearch } from './ModalSearch'
import * as storeConfig from '../../../../store.config'

const { decathlonSearch } = storeConfig

export type ProductSearch = {
  productId: string
  name: string
  slug: string
  imageUrl: string
  clickUrl: string
  offer?: {
    discountPercentage: number
    listPrice: number
    price: number
  }
}

type Suggestion = {
  term: string
}

export function SearchInput() {
  const [isOpen, setIsOpen] = useState(false)
  const [products, setProducts] = useState<ProductSearch[]>([])
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])
  const [term, setTerm] = useState('')
  const [termNotFoundResults, setTermNotFoundResults] = useState(false)
  const standardObj = getStandardObject()

  const isB2B = checkEnviromentIsB2B()

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if (!term) {
          const { data: topSearches } = await axios.get(
            'https://decathlonstore.myvtex.com/_v/api/decathlon-search/top_searches?engine=vtex'
          )

          setSuggestions(topSearches.searches)
          setProducts(topSearches?.products ?? [])
          setTermNotFoundResults(true)

          return
        }

        const { data: dataSuggestions } = await axios.get(
          `${decathlonSearch}/autocomplete_suggestions?engine=vtex`,
          {
            params: { term },
          }
        )

        if (!dataSuggestions.productSuggestions.products.length) {
          const params: ParamsApi = {
            term,
            page: 1,
            resultsPerPage: 5,
            sc: isB2B ? B2B.salesChannel : B2C.salesChannel,
            sort: 'relevance',
            engine: 'vtex',
          }

          const { data: searchProducts } = await axios.get(
            `https://decathlonstore.myvtex.com/_v3/api/decathlon-search/product/search?${qs.stringify(
              params
            )}`
          )

          const transformedProducts = transformProductSuggestions(
            searchProducts.products
          )

          setProducts(transformedProducts)
          setSuggestions([{ term }])
          setTermNotFoundResults(false)

          return
        }

        setSuggestions(dataSuggestions?.searchSuggestions?.searches)
        setProducts(dataSuggestions?.productSuggestions?.products)
        setTermNotFoundResults(false)
      } catch (error) {
        setProducts([])
        setSuggestions([])
        setTermNotFoundResults(true)
        console.error(error)
      }
    }

    fetchProducts()
  }, [isB2B, term])

  function handleSearch(searchTerm: string) {
    if (searchTerm.length < 1) {
      return
    }

    dispatchAmplitudeEvent({
      eventName: 'Search Completed',
      eventData: {
        ...standardObj,
        'search term': searchTerm,
      },
    })

    setIsOpen(false)
    navigate(
      `/pesquisa?q=${encodeURIComponent(searchTerm)}&sort=score_desc&page=0`
    )
  }

  const handleSearchPerformedEvent = useDebounce((value: string) => {
    dispatchAmplitudeEvent({
      eventName: 'Search Performed',
      eventData: {
        ...standardObj,
        'search term': value,
      },
    })
  }, 500)

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const cleanValue = DOMPurify.sanitize(e.target.value)

    setTerm(cleanValue)

    if (cleanValue.length < 3) {
      return
    }

    handleSearchPerformedEvent(cleanValue)
  }

  return (
    <div
      className={classNames('restructure-small-desktop:!relative', {
        'fixed z-10 top-0 left-0 restructure-small-desktop:top-auto restructure-small-desktop:left-auto w-screen h-screen restructure-small-desktop:w-auto restructure-small-desktop:h-auto pt-lg restructure-small-desktop:pt-0 bg-restructure-background-primary restructure-small-desktop:bg-transparent flex flex-col':
          isOpen,
      })}
    >
      {isOpen && (
        <button
          className="fixed top-0 left-0 z-10 w-screen h-screen cursor-default"
          onClick={() => setIsOpen(false)}
          aria-label="close modal search"
        />
      )}
      <div
        className={classNames(
          'px-md flex items-center gap-xs restructure-small-desktop:px-0',
          { '!px-0': !isOpen }
        )}
      >
        <form
          className={classNames(
            'relative flex-1 z-[9999] bg-restructure-background-secondary rounded-round flex items-center gap-xs px-sm py-xs',
            { 'border border-restructure-border-active': isOpen }
          )}
          onSubmit={(e) => {
            e.preventDefault()
            handleSearch(term)
          }}
        >
          <button type="submit" aria-label="Buscar" data-test-id="botao-busca">
            <LopOutline />
          </button>
          <input
            type="text"
            className="flex-1 bg-transparent outline-none desktop-body-regular-text2 text-restructure-secondary placeholder:text-restructure-secondary"
            placeholder="Pesquise por um produto ou esporte"
            onClick={() => setIsOpen(true)}
            value={term}
            onChange={(e) => handleChange(e)}
            data-test-id="input-busca"
          />
          {term.length > 0 && (
            <button
              className="absolute right-4"
              type="button"
              onClick={() => {
                setTerm('')
                setTermNotFoundResults(false)
              }}
            >
              <CloseOutline />
            </button>
          )}
        </form>
        {isOpen && (
          <button
            className="restructure-small-desktop:hidden rounded-round bg-restructure-background-secondary px-xs py-xs desktop-body-semibold-text3 text-restructure-action"
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </button>
        )}
      </div>
      <ModalSearch
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSearch={(searchTerm) => handleSearch(searchTerm ?? term)}
        products={products}
        suggestions={suggestions}
        term={term}
        resultNotFound={termNotFoundResults}
      />
    </div>
  )
}
