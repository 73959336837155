import type { VirtualPageEvent } from './types/virtualPageEvent'

type Window = {
  document: Document
  location: Location
}

type Document = {
  title: string
}

type Location = {
  pathname?: string
  href: string
  origin?: string
}

export const makeVirtualPageEvent = (
  window: Window,
  prevLocation: Location
): VirtualPageEvent => {
  return {
    event: 'pgv_virtual',
    page_title: window?.document?.title ?? '',
    page_path: window?.location?.pathname ?? '',
    page_location: window?.location?.href ?? '',
    document_referrer: prevLocation?.href ?? '',
  }
}
