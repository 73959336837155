import axios from 'axios'

import type { OrderFormAPI } from '../types'
import { OrderForm } from '../../entity/orderForm'

export const addCartMarketingData = async (
  orderFormId: string,
  body: OrderFormAPI['marketingData']
) => {
  try {
    const { data } = await axios.post(
      `/api/checkout/orderForm/${orderFormId}/marketingData`,
      body
    )

    return new OrderForm(data)
  } catch (error) {
    throw new Error(error)
  }
}
