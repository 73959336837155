import type { Item } from 'src/sdk/checkout/entity/orderForm'
import { SELLER_DECATHLON } from 'src/constants'

export function getStandardObject() {
  if (typeof document === 'undefined') {
    return null
  }

  const timestamp = new Date().toISOString().replace('T', ' ').replace('.', ' ')

  const timestampIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone

  return {
    'local timestamp': timestamp,
    'local timestamp identifier': timestampIdentifier,
    'website domain': document.location.origin,
    'page path': document.location.pathname,
    'page title': document.title,
    'page full url': document.location.href,
    'referring domain': document.referrer,
    'full referrer': null,
    'page name': document.location.pathname,
    'page type': checkPageType(),
    'page language': 'PT_BR',
    'previous page type': null,
    'previous page name': null,
    'page platform': 'WEB',
    'page number viewed': null,
  }
}

export function checkPageType() {
  const isCartPage = document.location.origin.includes('secure')
  const isAccountPage = document.location.pathname.includes('account')

  if (isCartPage) {
    return 'carrinho'
  }

  if (isAccountPage) {
    return 'perfil do cliente'
  }

  return 'public'
}

export function checkCartType(items: Item[]) {
  const hasDecathlon = items.find((item) => item.seller === SELLER_DECATHLON)
  const hasMarketPlace = items.find((item) => item.seller !== SELLER_DECATHLON)

  switch (true) {
    case hasDecathlon && !hasMarketPlace:
      return 'decathlon'

    case hasMarketPlace && !hasDecathlon:
      return 'marketplace'

    default:
      return 'mixed'
  }
}
