export const womenMenuData = {
  title: 'Feminino',
  url: '/#',
  typeDestinySectionLevel01: 'abrir-listagem',
  UriSectionLevel01: '',
  imageBannerDesktop: '/arquivos/image-empty-280x360.png',
  imageBannerMobile: '/arquivos/image-empty-337x190.png',
  linkBanner: '/#',
  typeDestinyBannerLevel01: 'abrir-listagem',
  UriBannerLevel01: '',
  iconAPP:
    'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/9ba6e8fd-1f6f-4b22-8f12-20a3a30fad20___ae40facf9201f5fd94673e42fbc5064b.png',
  categories: [
    {
      title: 'Produtos Mais Vendidos',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Mais Vendidos',
      pictoAPP:
        'https://decathlonstore.vteximg.com.br/arquivos/default-campo-05.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Calças e leggings',
          url: '/pesquisa/?q=Calças e leggings femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371517/&filter=d:genero:feminino',
        },
        {
          title: 'Camisetas',
          url: '/pesquisa/?q=Camisetas femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'Feminino > Roupas > Camisetas',
        },
        {
          title: 'Agasalhos e fleeces',
          url: '/pesquisa/?q=Agasalhos e fleeces femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371507/&filter=d:genero:feminino',
        },
        {
          title: 'Tênis',
          url: '/pesquisa/?q=Tênis feminino',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371539/&filter=d:genero:feminino',
        },
        {
          title: 'Botas',
          url: '/pesquisa/?q=Botas femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371532/&filter=d:genero:feminino',
        },
        {
          title: 'Mochilas, bolsas e malas',
          url: '/pesquisa/?q=Mochilas bolsas e malas femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371677/&filter=d:genero:feminino',
        },
        {
          title: 'Kits e conjuntos',
          url: '/pesquisa/?q=Kits e conjuntos femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371663/&filter=d:genero:feminino',
        },
        {
          title: 'Bermudas e shorts',
          url: '/pesquisa/?q=Bermudas e shorts femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371518/&filter=d:genero:feminino',
        },
        {
          title: 'Tops e croppeds',
          url: '/pesquisa/?q=Tops e croppeds femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371494/&filter=d:genero:feminino',
        },
        {
          title: 'Chinelos',
          url: '/pesquisa/?q=Chinelos femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371533/&filter=d:genero:feminino',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Roupas',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Roupa',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/69664f4d-a724-48b7-8284-ee7337d76758___310bbb9e8d5d4d7194a1755ee0dea3ee.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Tops e croppeds',
          url: '/pesquisa/?q=Tops e croppeds femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371494/&filter=d:genero:feminino',
        },
        {
          title: 'Camisetas',
          url: '/pesquisa/?q=Camisetas femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371495/&filter=d:genero:feminino',
        },
        {
          title: 'Jaquetas',
          url: '/pesquisa/?q=Jaquetas femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371499/&filter=d:genero:feminino',
        },
        {
          title: 'Agasalhos e fleeces',
          url: '/pesquisa/?q=Agasalhos e fleeces femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371488/72371507/&filter=d:genero:feminino',
        },
        {
          title: 'Biquínis',
          url: '/pesquisa/?q=Biquínis',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371489/72371512/&filter=d:genero:feminino',
        },
        {
          title: 'Maiôs',
          url: '/pesquisa/?q=Maiôs',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371489/72371510/&filter=d:genero:feminino',
        },
        {
          title: 'Bermudas e shorts',
          url: '/pesquisa/?q=Bermudas e shorts femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371518/&filter=d:genero:feminino',
        },
        {
          title: 'Calças e leggings',
          url: '/pesquisa/?q=Calças e leggings femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/72371490/72371517/&filter=d:genero:feminino',
        },
        {
          title: 'Todas roupas femininas',
          url: '/pesquisa/?q=Roupas femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371487/&filter=d:genero:feminino',
        },
      ],
    },
    {
      title: 'Calçados',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Calçados',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/c7e9abdb-4d05-43e0-afc2-4959b7b0cadc___8e8158a5d1e85441d8ada5034b891e56.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Botas',
          url: '/pesquisa/?q=Botas femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371532/&filter=d:genero:feminino',
        },
        {
          title: 'Chinelos',
          url: '/pesquisa/?q=Chinelos femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371533/&filter=d:genero:feminino',
        },
        {
          title: 'Botinas',
          url: '/pesquisa/?q=Botinas femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371535/&filter=d:genero:feminino',
        },
        {
          title: 'Chuteiras',
          url: '/pesquisa/?q=Chuteiras femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371536/&filter=d:genero:feminino',
        },
        {
          title: 'Sandálias',
          url: '/pesquisa/?q=Sandálias femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371537/&filter=d:genero:feminino',
        },
        {
          title: 'Sapatilhas',
          url: '/pesquisa/?q=Sapatilhas femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371538/&filter=d:genero:feminino',
        },
        {
          title: 'Tênis',
          url: '/pesquisa/?q=Tênis feminino',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/72371539/&filter=d:genero:feminino',
        },
        {
          title: 'Meias',
          url: '/pesquisa/?q=Meias femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371530/72371530/&filter=d:genero:feminino',
        },
        {
          title: 'Meias calças',
          url: '/pesquisa/?q=Meias calças femininas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371530/72371543/&filter=d:genero:feminino',
        },
        {
          title: 'Todos calçados femininos',
          url: '/pesquisa/?q=calçados femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371525/72371529/&filter=d:genero:feminino',
        },
      ],
    },
    {
      title: 'Acessórios',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Acessórios',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/d1763440-b855-49f8-a3b8-a040b1264e84___f5c7e3f4c0cf27228fc7322810eb4dd6.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Tapa bumbum',
          url: '/pesquisa/?q=Tapa bumbum',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371621/&filter=d:genero:feminino',
        },
        {
          title: 'Kits de proteção',
          url: '/pesquisa/?q=Kits de proteção',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371627/&filter=d:genero:feminino',
        },
        {
          title: 'Luvas',
          url: '/pesquisa/?q=Luvas',
          typeDestinyListing: 'destino-final-plp-collection-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371619/&filter=d:genero:feminino',
        },
        {
          title: 'Mochilas, bolsas e malas',
          url: '/pesquisa/?q=Mochilas bolsas e malas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371677/&filter=d:genero:feminino',
        },
        {
          title: 'Boné, chapéu e viseira',
          url: '/pesquisa/?q=Boné, chapéu e viseira feminina',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371609/&filter=d:genero:feminino',
        },
        {
          title: 'Fitas e faixas',
          url: '/pesquisa/?q=Fitas e faixas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371683/&filter=d:genero:feminino',
        },
        {
          title: 'Cintas e cintos',
          url: '/pesquisa/?q=Cintas e cintos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371628/&filter=d:genero:feminino',
        },
        {
          title: 'Necessaire pochete',
          url: '/pesquisa/?q=Necessaire pochete',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/72371626/&filter=d:genero:feminino',
        },
        {
          title: 'Toalhas',
          url: '/pesquisa/?q=Toalhas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371552/72371689/&filter=d:genero:feminino',
        },
        {
          title: 'Todos acessórios femininos',
          url: '/pesquisa/?q=acessórios femininos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371526/72371550/&filter=d:genero:feminino',
        },
      ],
    },
  ],
  includeModule: true,
}
