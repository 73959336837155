import axios from 'axios'

interface SessionResponse {
  isLoggedIn: boolean
}

export const getUserSession = async () => {
  const { data } = await axios.get<SessionResponse>(`/api/account/session`)

  return data
}
