import { Link } from 'gatsby'
import Logo from 'src/components/ui/Logo'
import Icon from 'src/components/ui/Icon'
import { Image } from 'src/components/ui/Image'
import { useMobile } from 'src/hooks/useMobile'

const AccountFooter = () => {
  const { isMobile } = useMobile()

  return (
    <div className="flex justify-center items-center w-full border-t-sm border-[#E1E4E7]">
      <div className="w-full flex flex-col items-center justify-center pt-10 pb-xxxl restructure-small-desktop:flex-row restructure-small-desktop:py-10 restructure-small-desktop:max-w-[1280px] restructure-small-desktop:justify-between">
        <Logo
          width={isMobile ? 160 : 188}
          height={isMobile ? 24 : 28}
          bgFill="#3643BA"
          className="min-w-[160px] restructure-small-desktop:min-w-[188px]"
        />
        <div className="flex flex-col items-center justify-center font-inter mt-[22px] mb-10 gap-1.5 restructure-small-desktop:m-0 restructure-small-desktop:gap-0 restructure-small-desktop:order-3 restructure-small-desktop:items-start">
          <div className="flex flex-col items-center justify-center gap-1 restructure-small-desktop:flex-row restructure-small-desktop:gap-2.5">
            <Icon name="BlackQuestionMark" width={24} height={24} />
            <p className="text-body-sm-link text-[#15181B]">
              Ficou com alguma dúvida?
            </p>
          </div>
          <Link
            to="/atendimento"
            className="text-darkGray text-body-sm underline mt-[2px] restructure-small-desktop:mt-0 restructure-small-desktop:pl-[34px]"
          >
            Fale conosco
          </Link>
        </div>
        <div className="flex gap-24 items-center restructure-small-desktop:order-2">
          {!isMobile && (
            <div className="flex flex-col mr-3 font-inter text-[#15181B]">
              <p className="leading-6 font-bold tracking-[-0.28px]">
                Transação segura
              </p>
              <p className="text-[12px] leading-5 tracking-[-0.24px]">
                Contra fraudes
              </p>
            </div>
          )}
          <Icon name="LetsEncrypt" width="48" height="34" />
          <Image
            width={37}
            height={34}
            src="https://decathlonstore.vtexassets.com/arquivos/pci.png"
            alt="PCI Certified"
            loading="lazy"
          />
          <Image
            width={92}
            height={24}
            src="https://decathlonstore.vtexassets.com/arquivos/konduto-footer.png"
            alt="Konduto"
            loading="lazy"
            className="h-[24px]"
          />
        </div>
      </div>
    </div>
  )
}

export default AccountFooter
