export const replaceCurrencyForString = (value: string) =>
  value
    .split('')
    .filter((char) => /\d/gi.test(char))
    .join('')
    .padStart(3, '0')

export const convertStringToNumber = (value: string) => {
  const decimalNumber = 0.01 * parseFloat(value.slice(-2))

  return parseFloat(value.slice(0, -2)) + decimalNumber
}

export const maskCurrency = (
  value: number,
  locale = 'pt-br',
  currency = 'BRL'
) => value?.toLocaleString(locale, { style: 'currency', currency })
