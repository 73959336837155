import type { ReactNode } from 'react'
import { useEffect, useState, createContext, useMemo } from 'react'

interface MemberProviderProps {
  children: ReactNode
}

interface Address {
  id: string
  vtexId: string
  title: string
  street: string
  locality: string
  firstname: string
  type: string
  accuracy: string
  phone_number: string | null
  lastname: string
  complement: string
  country_code: string
  postal_code: string
  is_favorite: boolean
  administrative_divisions: {
    province: string
    district: string
  }
}

interface Member {
  addresses: Address[]
  profile: any
}

interface ContextValue {
  member: Member | null
}

export const MemberContext = createContext<ContextValue>({} as ContextValue)

export const MemberProvider = ({ children }: MemberProviderProps) => {
  const [member, setMember] = useState<Member | null>(null)

  useEffect(() => {
    setMember({
      addresses: [
        {
          id: '840000010000048988',
          vtexId: '0d92501d-8f0b-11ee-8452-12559868d1ad',
          country_code: 'BRA',
          street: 'Av. Paulista, 20',
          locality: 'São Paulo',
          postal_code: '04460020',
          complement: 'ddsf',
          title: 'Casa',
          is_favorite: false,
          firstname: 'Raphael',
          lastname: 'Buritíãâ',
          phone_number: null,
          type: 'SHIPPING',
          accuracy: 'VALID',
          administrative_divisions: {
            province: 'SP',
            district: 'Bela Vista',
          },
        },
      ],
      profile: {
        claims: {
          email: 'rapha_buriti@decathlon.com',
          given_name: 'Raphael',
          middle_name: 'RaphaelBuriti',
          family_name: 'Buritíãâ',
          birthdate: null,
          phone_number: '+5511232322233',
          phone_verified: false,
          gender: 'male',
        },
        additional_information: [
          {
            id: 'cpf',
            value: '06086098034',
          },
        ],
        identifiers: [
          {
            id: 'loyalty_card',
            value: '2092594616703',
          },
          {
            id: 'email',
            value: 'rapha_buriti@decathlon.com',
          },
        ],
      },
    })
  }, [])

  const value = useMemo((): ContextValue => {
    return {
      member,
    }
  }, [member])

  return (
    <MemberContext.Provider value={value}>
      <>{children}</>
    </MemberContext.Provider>
  )
}
