import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'
import type { Level1 } from 'src/graphql/queries/menu'
import { stripUrl } from 'src/utils/stripUrl'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import DropdownContentVariations from './ContentVariations'
import { ChevronBlue } from '../HeaderMobile/components/utils/icons'

interface DropwdowMenuGender extends Level1 {
  variant?: 'default' | 'second'
  positionRight?: boolean
  urlDepartment?: string
  setInHovering: React.Dispatch<React.SetStateAction<boolean>>
}

export function urlToImage(image: string) {
  return image.includes('empty')
    ? `https://decathlonstore.vteximg.com.br${image}`
    : image
}

function DropdownMenuGender({
  title,
  categories = [],
  imageBannerDesktop,
  linkBanner,
  new_tabBanner,
  variant = 'default',
  positionRight = false,
  urlDepartment,
  setInHovering,
}: DropwdowMenuGender) {
  const categorieHighLight = categories.filter(
    (item) => item.highlight === true
  )

  const customStyles = {
    container: 'w-full max-h-[400px] lg:max-h-[400px]',
    contentNav: 'max-w-[291px]',
    containerDropdown: 'w-full',
    contentDropdown: 'columns-5 columns-to-count',
    wrapperDropdown: 'py-5',
    contentTitleDropdown: 'hidden',
    contentItemDropdownList: '',
    containerImageDesktop: 'hidden lg:block',
    imageDesktopHeight: 400,
  }

  const defaultStyles = {
    container: 'max-h-[360px]',
    contentNav: '',
    containerDropdown: 'w-[63.75%]',
    contentDropdown: 'columns-1 mt-3',
    wrapperDropdown: 'py-8',
    contentTitleDropdown: '',
    contentItemDropdownList: 'mt-2',
    containerImageDesktop: '',
    imageDesktopHeight: 360,
  }

  const menuStyles = variant === 'default' ? defaultStyles : customStyles

  menuStyles.container += positionRight
    ? ' right-0'
    : ' left-1/2 -translate-x-1/2'

  const handleSendEvent = (position: string, event: string | undefined) => {
    const eventTrack = makeEventTrack({
      eventAction: `${event}${position}`,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
  }

  return (
    <section
      className={`border-t border-gray absolute left-0 top-[47px] ${menuStyles.container} dropdown-content w-screen max-h-[70vh] pointer-events-none -z-10 opacity-0 transition-all duration-300 flex group-hover:z-10 group-hover:opacity-100 group-hover:pointer-events-auto absolute bg-white rounded-b-[4px] shadow-[0px 12px 12px 0px]`}
      style={{ boxShadow: '0px 12px 12px 0px #00537D1A' }}
    >
      <div className="flex w-full">
        <article className="flex w-full">
          {categorieHighLight.length ? (
            <div className="highligth min-w-[316px] p-10 bg-restructure-background-secondary text-left overflow-y-auto scrollbar-v2">
              <div className="desktop-body-regular-text2 text-restructure-primary mb-6">
                {categorieHighLight[0].title}
              </div>
              <ul>
                {categorieHighLight[0].subcategories?.map((subcategorie) => (
                  <li key={subcategorie.title}>
                    <Link
                      to={subcategorie.url ?? ''}
                      className="block desktop-heading-title5 text-restructure-secondary mb-6 hover:text-restructure-action"
                      aria-label={subcategorie.title}
                      title={subcategorie.title}
                      onClick={() => setInHovering(false)}
                    >
                      {subcategorie.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          <div className="w-full flex flex-col py-8 px-12 max-h-full overflow-y-scroll text-left scrollbar-v2">
            <div className="flex gap-4 items-center">
              <div className="desktop-heading-title5 text-restructure-secondary">
                {title}
              </div>
              {urlDepartment && urlDepartment !== '/#' && (
                <Link
                  to={urlDepartment}
                  className="flex items-center gap-2 desktop-body-semibold-text3 text-restructure-action bg-restructure-background-secondary rounded-round px-4 py-1 hover:bg-restructure-background-tertiary active:bg-restructure-background-neutral-4 disabled:opacity-80"
                  aria-label={title}
                  title={title}
                  data-testid="item-see-all"
                  onClick={() => {
                    handleSendEvent(' > Ver tudo - Menu', title)
                    setInHovering(false)
                  }}
                >
                  Ver tudo
                  <ChevronBlue />
                </Link>
              )}
            </div>
            <div className="flex flex-row gap-6 flex-wrap">
              {categories
                ?.filter((categorie) => !categorie.highlight)
                .map((subcategorie) => {
                  const propsSubcategories = {
                    typeThirtLevel: subcategorie.typeThirtLevel,
                    subcategories: subcategorie.subcategories,
                  }

                  return (
                    <ul className="w-[220px] mt-6" key={subcategorie.title}>
                      <DropdownContentVariations
                        {...propsSubcategories}
                        titleId={subcategorie.title}
                        menuStyles={menuStyles}
                        department={title ?? ''}
                        setInHovering={setInHovering}
                      />
                      {subcategorie.url && subcategorie.url !== '/#' && (
                        <Link
                          to={subcategorie.url ?? ''}
                          className="desktop-body-regular-text3-link text-restructure-action mt-2 block"
                          aria-label={subcategorie.title}
                          title={subcategorie.title}
                          onClick={() => {
                            handleSendEvent(
                              ' > Ver tudo - Menu',
                              `${title} > ${subcategorie.title}`
                            )
                            setInHovering(false)
                          }}
                        >
                          Ver tudo
                        </Link>
                      )}
                    </ul>
                  )
                })}
            </div>
          </div>
        </article>
        {!imageBannerDesktop.includes('empty') && (
          <article className={menuStyles.containerImageDesktop}>
            <a
              href={linkBanner}
              target={new_tabBanner ? '_blank' : '_top'}
              rel="noreferrer"
            >
              <Image
                src={stripUrl(urlToImage(imageBannerDesktop))}
                alt={`Banner Menu - ${title}`}
                width={280}
                height={menuStyles.imageDesktopHeight}
                loading="lazy"
                className={
                  imageBannerDesktop.includes('empty')
                    ? 'opacity-0'
                    : 'opacity-100'
                }
              />
            </a>
          </article>
        )}
      </div>
    </section>
  )
}

export default DropdownMenuGender
