import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useMobile } from 'src/hooks/useMobile'
import { Link } from 'gatsby'
import type {
  StripeItem,
  StripeSectionProps,
} from 'src/components/global/types'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'

function ConditionalLink({
  link,
  children,
}: {
  link?: string
  children: React.ReactNode
}) {
  const isTargetBlank = link?.charAt(0) !== '/' && !link?.includes('decathlon')

  return link ? (
    <Link
      rel="noreferrer"
      target={isTargetBlank ? '_blank' : '_self'}
      to={link}
    >
      {children}
    </Link>
  ) : (
    <div>{children}</div>
  )
}

export const Stripe = ({ stripeSection }: StripeSectionProps) => {
  const { isMobile } = useMobile()

  if (!stripeSection || !stripeSection.enableSession) {
    return (
      <div
        data-testid="section-stripe"
        className="flex items-center h-[32px]"
      />
    )
  }

  return (
    <div
      data-testid="section-stripe"
      className="flex justify-center bg-restructure-background-primary-inverted text-restructure-primary-inverted"
    >
      {stripeSection.stripes?.length > 1 ? (
        <Splide
          options={{
            perPage: 1,
            arrows: false,
            pagination: false,
            type: stripeSection.stripes?.length > 1 ? 'loop' : 'slide',
            direction: 'ttb',
            height: 40,
            cover: false,
            rewind: false,
            autoplay: true,
            interval: 4000,
            breakpoints: {
              900: {
                height: 32,
              },
            },
          }}
        >
          {stripeSection.stripes?.map((stripe: StripeItem) => (
            <SplideSlide
              key={stripe.stripeText}
              className="flex items-center justify-center"
            >
              <ConditionalLink link={stripe.link}>
                <div
                  className="flex items-center mobile-caption-regular desktop-body-regular-text3"
                  data-testid="stripe-item"
                >
                  {stripe.stripeText}
                  {stripe.icon && (
                    <img
                      alt="Icon"
                      className="ml-[3px]"
                      src={stripe.icon}
                      width={isMobile ? '16' : '24'}
                      height={isMobile ? '16' : '24'}
                    />
                  )}
                </div>
              </ConditionalLink>
            </SplideSlide>
          ))}
        </Splide>
      ) : (
        <div
          data-testid="section-stripe"
          className="flex items-center h-[32px]"
        >
          <span
            data-testid="stripe-item"
            className="mobile-caption-regular desktop-body-regular-text3"
          >
            <ConditionalLink link={stripeSection.stripes?.[0].link}>
              (
              <div className="flex items-center">
                {stripeSection.stripes?.[0].stripeText}
                {stripeSection.stripes?.[0]?.icon && (
                  <img
                    alt="Icon"
                    className="ml-[3px]"
                    src={stripeSection.stripes?.[0].icon}
                    width={isMobile ? '16' : '24'}
                    height={isMobile ? '16' : '24'}
                  />
                )}
              </div>
              )
            </ConditionalLink>
          </span>
        </div>
      )}
    </div>
  )
}
