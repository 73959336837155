export const storage = () => {
  const getItem = (key: string, type = 'local') => {
    const db = type === 'local' ? window.localStorage : window.sessionStorage

    const item = db.getItem(key)

    return item ? JSON.parse(item) : null
  }

  const setItem = (
    key: string,
    value: Record<string, unknown>,
    type = 'local'
  ) => {
    const db = type === 'local' ? window.localStorage : window.sessionStorage

    return db.setItem(key, JSON.stringify(value))
  }

  return {
    getItem,
    setItem,
  }
}
