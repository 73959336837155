export default function handleUsablePageName(pathname: string) {
  if (/^\/$/.test(pathname)) {
    return 'home'
  }

  if (/^\/404$/.test(pathname)) {
    return '404'
  }

  if (/^\/esportes\//.test(pathname)) {
    return 'página de departamento'
  }

  if (/^\/checkout\//.test(pathname)) {
    return 'carrinho'
  }

  if (/^\/pesquisa/.test(pathname)) {
    return 'busca'
  }

  if (/\/p$/.test(pathname)) {
    return 'página de produto'
  }

  if (/\/kit$/.test(pathname)) {
    return 'kit look'
  }

  return 'plp'
}
