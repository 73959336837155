import { useRef, useState } from 'react'
import type { Level2, Level3BannerBlock } from 'src/graphql/queries/menu'
import Button from 'src/components/ui/Button'
import { Link } from 'gatsby'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import classNames from 'classnames'

import DropdownContentVariations from '../../Menu/ContentVariations'
import { ContentTitleNav } from './ContentTitleNav'
import { TitleSubmenu } from './TitleSubmenu'
import { ChevronBlue } from './utils/icons'
import { BannersBlock } from '../../Menu/ContentVariations/BannersBlock'

export const NavItemSecondLevel = ({
  department,
  item,
  setMenuIsOpen,
}: {
  department: string
  item: Level2
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [openNavigation, setOpenNavigation] = useState(false)
  const [delayToClose, setDelayToClose] = useState(false)
  const submenuRef = useRef<HTMLDivElement>(null)

  const handleOpenNavigation = () => {
    setOpenNavigation(true)
    setDelayToClose(true)
  }

  const handleCloseNavigation = () => {
    setOpenNavigation(false)

    setTimeout(() => {
      setDelayToClose(false)
    }, 4000)
  }

  const handleSendEvent = (position: string, event: string | undefined) => {
    const eventTrack = makeEventTrack({
      eventAction: `${event}${position}`,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
  }

  return (
    <>
      {item.typeThirtLevel === '3.6 Bloco de banners' ? (
        <li className="mb-8">
          <ContentTitleNav
            title={item.title ?? ''}
            version="esportes em destaque"
            showIcon={false}
          />
          <BannersBlock
            typeThirtLevel={item.typeThirtLevel}
            subcategories={(item.subcategories as Level3BannerBlock[]) ?? []}
            titleId={item.title ?? ''}
          />
        </li>
      ) : (
        <li className="mb-4">
          {item.subcategories?.length ? (
            <Button
              type="button"
              aria-label="abrir navcontent"
              className="w-full h-full !p-0 flex justify-between"
              onClick={() => {
                handleOpenNavigation()
              }}
            >
              <ContentTitleNav title={item.title ?? ''} version="v2" />
            </Button>
          ) : (
            <Link
              className="w-full h-full !p-0 flex justify-between"
              to={item.url ?? ''}
              aria-label={item.title}
              rel="noreferrer"
              onClick={() => {
                handleOpenNavigation()
              }}
            >
              <ContentTitleNav title={item.title ?? ''} version="v2" />
            </Link>
          )}

          {(openNavigation || delayToClose) && (
            <div
              className={classNames(
                'nav-content fixed h-full top-0 left-0 w-full z-[1] bg-white pb-[57px] ',
                {
                  'entrance-animation': openNavigation,
                },
                {
                  'exit-animation': !openNavigation,
                }
              )}
            >
              <div
                ref={submenuRef}
                className="flex flex-col h-full w-full overflow-y-auto overflow-x-hidden scrollbar-v2"
              >
                <TitleSubmenu
                  title={item.title ?? ''}
                  actionClose={handleCloseNavigation}
                  setMenuIsOpen={setMenuIsOpen}
                />
                <ul className="flex flex-col px-7 bg-white last-of-type:pb-6">
                  <DropdownContentVariations
                    department={department}
                    categorie={item.title}
                    subcategories={item.subcategories ?? []}
                    typeThirtLevel={item.typeThirtLevel}
                    isMobile
                    setMenuIsOpen={setMenuIsOpen}
                  />
                </ul>
                {item.url && item.url !== '/#' && (
                  <div className="fixed bottom-0 w-full flex items-center gap-2 py-4 px-7 border-t-[1px] border-solid border-restructure-border-primary mt-[9px]">
                    <Link
                      className="block mobile-body-semibold-text3  desktop-body-semibold-text3 text-restructure-action"
                      to={item.url ?? ''}
                      aria-label={item.title ?? ''}
                      rel="noreferrer"
                      onClick={() => {
                        handleOpenNavigation()
                        handleSendEvent(
                          ' > Ver tudo - Menu',
                          `${department} > ${item.title}`
                        )
                      }}
                    >
                      Ver tudo em {item.title}
                    </Link>
                    <ChevronBlue />
                  </div>
                )}
              </div>
            </div>
          )}
        </li>
      )}
    </>
  )
}
