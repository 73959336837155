interface OverlayProps {
  show: boolean
}

export const Overlay = ({ show }: OverlayProps) => {
  return (
    <div
      className={`${
        show ? 'opacity-80' : 'opacity-0'
      } absolute w-full h-full transition-all duration-300 ease-out bg-restructure-background-primary-inverted`}
    />
  )
}
