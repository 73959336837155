import { highlightSports } from './highlightSports'
import { allSports } from './allSports'

export const sportsMenuData = {
  title: 'Navegue por Esportes',
  url: '/#',
  typeDestinySectionLevel01: 'abrir-listagem',
  UriSectionLevel01: '',
  imageBannerDesktop: '/arquivos/image-empty-280x360.png',
  imageBannerMobile: '/arquivos/image-empty-337x190.png',
  linkBanner: '/#',
  typeDestinyBannerLevel01: 'abrir-listagem',
  UriBannerLevel01: '',
  iconAPP:
    'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/b1d697e5-7b9a-4fee-9422-56450bba05fb___b68495d71a55539926956601939fcf36.png',
  categories: [
    highlightSports,
    allSports,
    {
      title: 'Raquetes',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Raquetes',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/dbce0d98-da71-4453-9576-3835012247da___f247ae2219f426ebc7fcc4b302634553.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Badminton',
          url: 'https://www.decathlon.com.br/badminton',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:badminton',
        },
        {
          title: 'Frescobol',
          url: 'https://www.decathlon.com.br/frescobol',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:frescobol',
        },
        {
          title: 'Speedball',
          url: 'https://www.decathlon.com.br/speedball',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:speedball',
        },
        {
          title: 'Squash',
          url: 'https://www.decathlon.com.br/squash',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:squash',
        },
        {
          title: 'Tênis de mesa',
          url: 'https://www.decathlon.com.br/tenis-de-mesa-e-ping-pong',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:tenis-de-mesa',
        },
        {
          title: 'Tennis',
          url: 'https://www.decathlon.com.br/tennis',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:tennis',
        },
        {
          title: 'Padel',
          url: 'https://www.decathlon.com.br/padel',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:padel',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Alvo e precisão',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Alvo',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/4ff7a84c-072a-4ce6-abd0-0522658d65ff___82e8599bec088cefbf5a45e4a0053d2b.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Dardos',
          url: 'https://www.decathlon.com.br/outros-esportes/dardos',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:dardos',
        },
        {
          title: 'Arco e flecha',
          url: 'https://www.decathlon.com.br/arco-e-flecha',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:arco-e-flecha',
        },
        {
          title: 'Golf',
          url: 'https://www.decathlon.com.br/golf',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:golf',
        },
        {
          title: 'Petanca',
          url: 'https://www.decathlon.com.br/pesquisa/?q=petanca',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:petanca',
        },
        {
          title: 'Bets',
          url: 'https://www.decathlon.com.br/outros-esportes/taco-de-bets',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:bets',
        },
        {
          title: 'Peteca',
          url: 'https://www.decathlon.com.br/peteca',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:peteca',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Aquáticos no mar',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Aquáticos do Mar',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/badedf0f-5240-4c40-ac63-d97884d6b093___d283cbbc03dc9d587f14170aafb22d9c.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Stand up paddle',
          url: 'https://www.decathlon.com.br/stand-up-paddle',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:stand-up-paddle',
        },
        {
          title: 'Barco a vela',
          url: 'https://www.decathlon.com.br/barco-a-vela',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:barco-a-vela',
        },
        {
          title: 'Caiaque',
          url: 'https://www.decathlon.com.br/caiaque',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:caiaque',
        },
        {
          title: 'Mergulho e snorkeling',
          url: 'https://www.decathlon.com.br/mergulho-e-snorkeling',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:mergulho-e-snorkeling',
        },
        {
          title: 'Surf e bodyboard',
          url: 'https://www.decathlon.com.br/surf-e-bodyboard',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:surf-e-bodyboard',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Aquáticos na Piscina',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Aquáticos na Piscina',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/aec9cff3-ed5f-44eb-a4ee-92a0f8840798___1b4433de4240d3aed03352f4c3d92525.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Hidroginástica',
          url: 'https://www.decathlon.com.br/hidroginastica',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:hidroginastica',
        },
        {
          title: 'Natação',
          url: 'https://www.decathlon.com.br/natacao',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:natacao',
        },
        {
          title: 'Polo aquático',
          url: 'https://www.decathlon.com.br/polo-aquatico',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:polo-aquatico',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Dança',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Dança',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/98fdeeff-84d4-4bf1-94b0-0e4be999cae2___81cf089b953e0ee817bda3a3f0fbee20.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Ballet',
          url: 'https://www.decathlon.com.br/ballet',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:ballet',
        },
        {
          title: 'Dança moderna',
          url: 'https://www.decathlon.com.br/danca-moderna',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:danca-moderna',
        },
        {
          title: 'Ginástica artística e rítmica',
          url: 'https://www.decathlon.com.br/ginastica-artistica-e-ritmica',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:ginastica-artistica-e-ritmica',
        },
      ],
      includeModule: false,
    },
    {
      title: 'Areia',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Aquáticos do Mar',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/c02681ea-3743-4966-a994-42acf160a338___96209783e3e161e92a05141a3cc12d13.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Beach tênis',
          url: 'https://www.decathlon.com.br/beach-tennis',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:beach-tennis',
        },
        {
          title: 'Vôlei de praia',
          url: 'https://www.decathlon.com.br/volei-de-praia',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:volei-de-praia',
        },
        {
          title: 'Futebol de areia',
          url: 'https://www.decathlon.com.br/futebol-de-areia',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:futebol-de-areia',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Musculação & Fitness',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Musculação & Fitness',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/e2cc42c0-4650-4d7c-9e1e-1d888fbe11d5___c3cb7b8c8c7bcf1eee7448c435144fd1.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Funcional e cross training',
          url: 'https://www.decathlon.com.br/funcional-e-cross-training',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:funcional-e-cross-training',
        },
        {
          title: 'Musculação',
          url: 'https://www.decathlon.com.br/musculacao',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:musculação',
        },
        {
          title: 'Treino cardio',
          url: 'https://www.decathlon.com.br/treino-cardio',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:treino-cardio',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Pilates e Yoga',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Pilates e Yoga',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/6f3089e7-2c3c-42ad-9971-55ca9d5e9a01___b18aca563a34127fb510136eb061b5d1.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Pilates',
          url: 'https://www.decathlon.com.br/pilates-e-ginastica-adultos',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:pilates',
        },
        {
          title: 'Yoga',
          url: 'https://www.decathlon.com.br/yoga',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:yoga',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Esportes em time',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Coletivos',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/bfc0580f-db23-4d5c-a72d-486366ebdb0d___4abd52412f92ca0d799506d22d87ae65.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Basquete',
          url: 'https://www.decathlon.com.br/basquete',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:basquete',
        },
        {
          title: 'Beisebol',
          url: 'https://www.decathlon.com.br/beisebol',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:beisebol',
        },
        {
          title: 'Futebol',
          url: 'https://www.decathlon.com.br/futebol-de-campo',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:futebol',
        },
        {
          title: 'Futebol americano',
          url: 'https://www.decathlon.com.br/futebol-americano',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:futebol-americano',
        },
        {
          title: 'Handebol',
          url: 'https://www.decathlon.com.br/handebol',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:handebol',
        },
        {
          title: 'Rugby',
          url: 'https://www.decathlon.com.br/rugby',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:rugby',
        },
        {
          title: 'Vôlei',
          url: 'https://www.decathlon.com.br/volei-de-quadra',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:volei',
        },
        {
          title: 'Society',
          url: 'https://www.decathlon.com.br/society',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:society',
        },
        {
          title: 'Futsal',
          url: 'https://www.decathlon.com.br/futsal',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:futsal',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Montanha, camping & trilha',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Montanha, Camping e Trilha',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/8ca69c43-c1d2-40a5-b816-3623b2cf3933___df7e8f0fd2f243f3c6775fc303999d61.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Trilha e Trekking',
          url: 'https://www.decathlon.com.br/trilha-e-trekking',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:trilha-e-trekking',
        },
        {
          title: 'Camping',
          url: 'https://www.decathlon.com.br/camping',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:camping',
        },
        {
          title: 'Escalada e Alpinismo',
          url: 'https://www.decathlon.com.br/escalada-e-alpinismo',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:escalada-e-alpinismo',
        },
        {
          title: 'Observação da natureza',
          url: 'https://www.decathlon.com.br/observacao-da-natureza',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:observacao-da-natureza',
        },
        {
          title: 'Ski e snowboard',
          url: 'https://www.decathlon.com.br/ski-e-snowboard',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:ski-e-snowboard',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Luta',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Luta',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/b1a33e83-4d41-4320-ba7f-8ecaf2c8ea4b___f6bfe7d243b7a4b9b0fe0d59cbd51dc8.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Boxe e muay thai',
          url: 'https://www.decathlon.com.br/boxe-e-muay-thai',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:boxe-e-muay-thai',
        },
        {
          title: 'Jiu Jitsu',
          url: 'https://www.decathlon.com.br/jiu-jitsu',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:jiu-jitsu',
        },
        {
          title: 'Judô',
          url: 'https://www.decathlon.com.br/judo',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:judo',
        },
        {
          title: 'Karatê',
          url: 'https://www.decathlon.com.br/karate',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:karate',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Corrida e caminhada',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Novidades',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/d51346c4-bfcd-4cf5-bf82-2be0c8f4b998___ab0cc6c68f2294e2a80576a991b0a219.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Caminhada',
          url: 'https://www.decathlon.com.br/caminhada',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:caminhada',
        },
        {
          title: 'Corrida',
          url: 'https://www.decathlon.com.br/corrida',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:corrida',
        },
        {
          title: 'Trail running',
          url: 'https://www.decathlon.com.br/trail-running',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:trail-running',
        },
        {
          title: 'Atletismo',
          url: 'https://www.decathlon.com.br/atletismo',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:atletismo',
        },
        {
          title: 'Triathlon',
          url: 'https://www.decathlon.com.br/triathlon',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:triathlon',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Ciclismo',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Ciclismo',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/de30c408-b001-4010-8076-2e6cea6c32e2___2fcdbbdf983081c05ac389a01b155cda.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Estrada',
          url: 'https://www.decathlon.com.br/ciclismo-estrada',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:cilismo-estrada',
        },
        {
          title: 'Infantil',
          url: 'https://www.decathlon.com.br/ciclismo-Infantil',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:ciclismo-infantil',
        },
        {
          title: 'Mountain bike',
          url: 'https://www.decathlon.com.br/ciclismo-Mountain-bike',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:mountain-bike',
        },
        {
          title: 'Urbano',
          url: 'https://www.decathlon.com.br/ciclismo-Urbano',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:ciclismo-urbano',
        },
        {
          title: 'Elétrico',
          url: 'https://www.decathlon.com.br/pesquisa?q=ciclismo+eletrico&sort=score_desc&page=0',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:ciclismo-eletricos',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Esportes Urbanos',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Deslize Urbano',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/ae4c472b-3d95-43c6-a476-3124771970b8___39c8e8ba4ba75bcdf27801048ea6edd0.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Patinete',
          url: 'https://www.decathlon.com.br/patinete',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:patinete',
        },
        {
          title: 'Patins',
          url: 'https://www.decathlon.com.br/patins',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:patins',
        },
        {
          title: 'Skate',
          url: 'decathlon.com.br/skate',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:skate',
        },
      ],
      includeModule: true,
    },
    {
      title: 'Esportes ao ar livre',
      url: '',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Esportes ao Ar Livre',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/00537e62-f730-49d7-ab79-a01ed71ddda3___9632e8fbb4c8c6d7cf8026286bdfa841.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Bumerangue',
          url: 'https://www.decathlon.com.br/outros-esportes/bumerangues',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:bumetangue',
        },
        {
          title: 'Pipa',
          url: 'https://www.decathlon.com.br/outros-esportes/pipas',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:pipa',
        },
        {
          title: 'Frisbie',
          url: 'https://www.decathlon.com.br/outros-esportes/frisbee',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:frisbee',
        },
        {
          title: 'Slackline',
          url: 'https://www.decathlon.com.br/outros-esportes/slackline',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:slackline',
        },
        {
          title: 'Hipismo',
          url: 'https://www.decathlon.com.br/hipismo',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:hipismo',
        },
        {
          title: 'Pesca',
          url: 'https://www.decathlon.com.br/pesca',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:esporte:pesca',
        },
      ],
      includeModule: true,
    },
  ],
  includeModule: false,
}
