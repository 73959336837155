import axios from 'axios'

import { OrderForm } from '../../entity/orderForm'
import type { OrderItemOnAdd, OrderItemOnUpdate } from '../types'

export const addCartItems = async (
  orderFormId: string,
  orderItems: OrderItemOnAdd[]
) => {
  const { data } = await axios.post(
    `/api/checkout/orderForm/${orderFormId}/items`,
    {
      orderItems,
    },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  )

  return new OrderForm(data)
}

export const updateCartItems = async (
  orderFormId: string,
  orderItems: OrderItemOnUpdate[]
) => {
  const { data } = await axios.post(
    `/api/checkout/orderForm/${orderFormId}/items/update?allowedOutdatedData=paymentData`,
    {
      orderItems,
    },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  )

  return new OrderForm(data)
}
