export const getCookie = (cname: string) => {
  const isBrowser = typeof window !== `undefined`

  if (!isBrowser) {
    return ''
  }

  const name = `${cname}=`
  const ca = document.cookie.split(';')

  for (const element of ca) {
    let c = element

    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }

  return ''
}

export function deleteCookie(cookieName: string) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export const readCookie = (cookie: string): boolean => {
  if (typeof document !== 'undefined') {
    return document.cookie.split('; ').includes(cookie)
  }

  return false
}

export const setCookie = (
  cookie: string,
  value: string,
  days: number
): void => {
  const date = new Date()

  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`

  if (typeof document !== 'undefined') {
    document.cookie = `${cookie}=${value};${expires};path=/`
    document.cookie = `${cookie}=${value};${expires};path=/;domain=.dktlab.com.br`
    document.cookie = `${cookie}=${value};${expires};path=/;domain=.decathlon.com.br`
    document.cookie = `${cookie}=${value};${expires};path=/;domain=.sfj-5066a91--decathlonstore.preview.vtex.app`
  }
}

export const getCookieValue = (
  pattern: string
): Record<string, unknown> | undefined => {
  if (typeof document !== 'undefined') {
    const result = document.cookie
      .split('; ')
      .filter((item) => item.includes(pattern))

    const result2 = result.map((item) => {
      return item.split('=')
    })

    return Object.fromEntries(result2)
  }

  return undefined
}

export const calculateExpirationDate = (lifetimeInSeconds: number) => {
  const currentDate = new Date()
  const expirationDate = new Date(
    currentDate.getTime() + lifetimeInSeconds * 1000
  )

  return expirationDate.toUTCString()
}

export const getDomain = (returnUrl: string) => {
  if (returnUrl?.includes('decathlon.com')) {
    return '.decathlon.com.br'
  }

  if (
    returnUrl?.includes('vtex.app') ||
    returnUrl?.includes('decathlonstore')
  ) {
    return '.vtex.app'
  }

  if (returnUrl?.includes('localhost')) {
    return 'localhost'
  }

  return '.decathlon.com.br'
}
