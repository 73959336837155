import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import LogOut from './LogOut'

const loggedLinks = [
  {
    text: 'Minha conta',
    url: '/account/',
    areaLabel: 'Ir para minha conta',
  },
  {
    text: 'Meus pedidos',
    url: '/account/orders',
    areaLabel: 'Ir para meus pedidos',
  },
  {
    text: 'Trocas e devoluções',
    url: '/account/exchanges',
    areaLabel: 'Ir para trocas e devoluções',
  },
  {
    text: 'Clube Decathlon',
    url: '/clube',
    areaLabel: 'Ir para clube decathlon',
  },
]

const LoggedInContent = () => {
  const location = useLocation()

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    const event = makeEventTrack({
      eventAction: e.currentTarget.innerText,
      eventPage: location.pathname === '/' ? 'home' : location.pathname,
    })

    sendEvent(event)
  }

  return (
    <div>
      <ul className="flex flex-col gap-3">
        {loggedLinks.map((links) => {
          return (
            <li
              className="text-sm font-normal font-inter hover:font-semibold"
              key={links.url}
            >
              <Link
                aria-label={links.areaLabel}
                to={links.url}
                onClick={(e) => handleClick(e)}
              >
                {links.text}
              </Link>
            </li>
          )
        })}
      </ul>
      <div className="block w-full h-px bg-neutral04 my-3" />

      <LogOut className="text-sm" />
    </div>
  )
}

export default LoggedInContent
