import { useLocation } from '@reach/router'
import { LogOutIcon } from 'src/components/Icons/LogOutIcon'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import { logoutUser } from 'src/utils/account/logoutUser'

type LogOutProps = {
  className?: string
}

const LogOut = ({ className = '' }: LogOutProps) => {
  const { pathname } = useLocation()

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    sendEvent(
      makeEventTrack({
        eventAction: event.currentTarget.ariaLabel!,
        eventPage: pathname === '/' ? 'home' : pathname ?? '',
      })
    )
  }

  return (
    <div className={className}>
      <button
        className="flex items-center gap-2"
        area-aria-label="Fazer logout"
        onClick={(event) => {
          handleClick(event)
          logoutUser({
            pathname: '/',
          })
        }}
      >
        <LogOutIcon />
        <span className="font-inter font-normal hover:font-semibold">Sair</span>
      </button>
    </div>
  )
}

export default LogOut
