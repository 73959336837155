import type {
  OrderFormAPI,
  ItemAPI,
  SellerAPI,
} from '../data/types/orderformAPI'
import { formatterPrice } from '../data/utils/formatterPrice'

interface ItemProps {
  item: ItemAPI
  itemIndex: number
  sellers: SellerAPI[]
}

export class Item {
  public id: string
  public productId: string
  public productRefId: string
  public refId: string
  public ean: string
  public name: string
  public skuName: string
  public productName: string
  public imageUrl: string
  public price: string
  public listPrice: string
  public sellingPrice: string
  public quantity: number
  public availability: string
  public seller: string
  public sellerName: string
  public detailUrl: string
  public itemIndex: number
  public brand: string
  public brandId: string
  public category: string
  public categoryName: string
  public categoryId: string
  public categoryTree: string[]
  public categoryIdTree: string[]
  public isGift: boolean
  public tax: string
  public components: any[]
  public measurementUnit: string
  public unitMultiplier: number
  public hasDiscount: boolean
  public additionalInformation: {
    color: string
    size: string
    analytics: {
      amplitude: any
    }
    sellerOffer: {
      cashDiscountPercentage: number
      cashPrice: number
      hasDiscount: boolean
      listPrice: number
      price: number
    }
  }

  constructor({ item, sellers, itemIndex }: ItemProps) {
    this.id = item.id
    this.productId = item.productId
    this.productRefId = item.productRefId
    this.refId = item.refId
    this.ean = item.ean
    this.name = item.name
    this.skuName = item.skuName
    this.imageUrl = item.imageUrl
    this.detailUrl = item.detailUrl
    this.quantity = item.quantity
    this.availability = item.availability
    this.seller = item.seller
    this.price = formatterPrice(item.price / 100)
    this.listPrice = formatterPrice(item.listPrice / 100)
    this.productName =
      item.name === item.skuName
        ? item.name
        : item.name.replace(item.skuName, '')
    this.sellerName = this.setSellerName(item.seller, sellers)
    this.itemIndex = itemIndex
    this.brand = item.additionalInfo.brandName
    this.brandId = item.additionalInfo.brandId
    this.category = Object.values(item.productCategories).join('/')
    this.categoryName = Object.values(item.productCategories).pop() ?? ''
    this.categoryId = Object.keys(item.productCategories).pop() ?? ''
    this.categoryTree = Object.values(item.productCategories)
    this.categoryIdTree = Object.keys(item.productCategories)
    this.isGift = item.isGift
    this.sellingPrice = formatterPrice(item.sellingPrice / 100)
    this.tax = formatterPrice(item.tax / 100)
    this.components = item.components
    this.measurementUnit = item.measurementUnit
    this.unitMultiplier = item.unitMultiplier
    this.additionalInformation = item.additionalInformation
    this.sellingPrice = formatterPrice(item.sellingPrice / 100)
    this.hasDiscount = item.listPrice > item.sellingPrice
  }

  private setSellerName(sellerId: string, sellers: SellerAPI[]) {
    const [{ name }] = sellers.filter((seller) => seller.id === sellerId)

    return name.replace('Iguasport LTDA', 'Decathlon')
  }
}

export interface Message {
  code: string
  fields: {
    [field: string]: string
  }
  status: 'error' | 'success' | 'warning'
  text: string
}

export class OrderForm {
  public orderFormId: string
  public messages: Message[] = []
  public items: Item[] = []
  public totalizers: {
    [k: string]: string
  }

  public clientProfileData: OrderFormAPI['clientProfileData']

  public shippingData: OrderFormAPI['shippingData']

  public loggedIn: OrderFormAPI['loggedIn']

  public clientPreferencesData: OrderFormAPI['clientPreferencesData']

  public paymentData: OrderFormAPI['paymentData']

  public marketingData: OrderFormAPI['marketingData']

  constructor(orderFormApi: OrderFormAPI) {
    this.orderFormId = orderFormApi?.orderFormId
    this.items = orderFormApi?.items?.map(
      (item, idx) =>
        new Item({ item, itemIndex: idx, sellers: orderFormApi.sellers })
    )
    this.clientProfileData = orderFormApi?.clientProfileData
    this.messages = orderFormApi?.messages
    this.totalizers = Object.fromEntries([
      ['total', formatterPrice(orderFormApi.value / 100)],
      ...(orderFormApi?.totalizers ?? []).map((t) => [
        t.id.toLowerCase(),
        `R$ ${formatterPrice(t.value / 100, 'decimal')}`,
      ]),
    ])
    this.loggedIn = orderFormApi?.loggedIn
    this.shippingData = orderFormApi?.shippingData
    this.clientPreferencesData = orderFormApi?.clientPreferencesData
    this.paymentData = orderFormApi?.paymentData
    this.marketingData = orderFormApi?.marketingData
  }
}
