export const allSports = {
  title: 'Todos os esportes',
  url: '/#',
  typeDestinySectionLevel02: 'abrir-listagem',
  UriSectionLevel02: '',
  picto: 'Todos os Esportes',
  pictoAPP:
    'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/d84c1b79-5fa7-4d27-beba-9d584c939bd7___9d8f87ee0e784bc01b91aec49f51b797.png',
  typeThirtLevel: '3.5 Listagem simples',
  subcategories: [
    {
      title: 'Arco e flecha',
      url: 'https://www.decathlon.com.br/arco-e-flecha',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:arco-e-flecha',
    },
    {
      title: 'Atletismo',
      url: 'https://www.decathlon.com.br/atletismo',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:atletismo',
    },
    {
      title: 'Badminton',
      url: 'https://www.decathlon.com.br/badminton',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:badminton',
    },
    {
      title: 'Ballet',
      url: 'https://www.decathlon.com.br/ballet',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:ballet',
    },
    {
      title: 'Barco a vela',
      url: 'https://www.decathlon.com.br/barco-a-vela',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:barco-a-vela',
    },
    {
      title: 'Basquete',
      url: 'https://www.decathlon.com.br/basquete',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:basquete',
    },
    {
      title: 'Beach tênis',
      url: 'https://www.decathlon.com.br/beach-tennis',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:beach-tennis',
    },
    {
      title: 'Beisebol',
      url: 'https://www.decathlon.com.br/beisebol',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:beisebol',
    },
    {
      title: 'Bets',
      url: 'https://www.decathlon.com.br/outros-esportes/taco-de-bets',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:bets',
    },
    {
      title: 'Boxe e muay thai',
      url: 'https://www.decathlon.com.br/boxe-e-muay-thai',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:boxe-e-muay-thai',
    },
    {
      title: 'Bumerangue',
      url: 'https://www.decathlon.com.br/outros-esportes/bumerangues',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:bumerangue',
    },
    {
      title: 'Caiaque',
      url: 'https://www.decathlon.com.br/caiaque',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:caiaque',
    },
    {
      title: 'Caminhada',
      url: 'https://www.decathlon.com.br/caminhada',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:caminhada',
    },
    {
      title: 'Camping',
      url: 'https://www.decathlon.com.br/camping',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:camping',
    },
    {
      title: 'Ciclismo estrada',
      url: 'https://www.decathlon.com.br/ciclismo-estrada',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:cilismo-estrada',
    },
    {
      title: 'Dança moderna',
      url: 'https://www.decathlon.com.br/danca-moderna',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:danca-moderna',
    },
    {
      title: 'Ciclismo urbano',
      url: 'https://www.decathlon.com.br/ciclismo-urbano',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:ciclismo-urbano',
    },
    {
      title: 'Corrida',
      url: 'https://www.decathlon.com.br/corrida',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:corrida',
    },
    {
      title: 'Dardos',
      url: 'https://www.decathlon.com.br/outros-esportes/dardos',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:dardos',
    },
    {
      title: 'Ciclismo infantil ',
      url: 'https://www.decathlon.com.br/ciclismo-Infantil',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:ciclismo-infantil',
    },
    {
      title: 'Escalada e Alpinismo',
      url: 'https://www.decathlon.com.br/escalada-e-alpinismo',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:escalada-e-alpinismo',
    },
    {
      title: 'Frescobol',
      url: 'https://www.decathlon.com.br/frescobol',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:frescobol',
    },
    {
      title: 'Frisbie',
      url: 'https://www.decathlon.com.br/outros-esportes/frisbee',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:frisbee',
    },
    {
      title: 'Funcional e cross training',
      url: 'https://www.decathlon.com.br/funcional-e-cross-training',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:funcional-e-cross-training',
    },
    {
      title: 'Futebol de campo',
      url: 'https://www.decathlon.com.br/futebol-de-campo',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:futebol',
    },
    {
      title: 'Futebol americano',
      url: 'https://www.decathlon.com.br/futebol-americano',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:futebol-americano',
    },
    {
      title: 'Futebol de areia',
      url: 'https://www.decathlon.com.br/futebol-de-areia',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:futebol-de-areia',
    },
    {
      title: 'Futsal',
      url: 'https://www.decathlon.com.br/futsal',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:futsal',
    },
    {
      title: 'Ginástica Artística e Rítmica',
      url: 'https://www.decathlon.com.br/pesquisa/?q=ginastica-artistica-e-ritmica',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:ginastica-artistica-e-ritmica',
    },
    {
      title: 'Golf',
      url: 'https://www.decathlon.com.br/golf',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:golf',
    },
    {
      title: 'Handebol',
      url: 'https://www.decathlon.com.br/handebol',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:handebol',
    },
    {
      title: 'Hidroginástica',
      url: 'https://www.decathlon.com.br/hidroginastica',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:hidroginastica',
    },
    {
      title: 'Hipismo',
      url: 'https://www.decathlon.com.br/hipismo',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:hipismo',
    },
    {
      title: 'Hóquei na grama',
      url: 'https://www.decathlon.com.br/hoquei-na-grama',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:hoquei-na-grama',
    },
    {
      title: 'Jiu-Jitsu',
      url: 'https://www.decathlon.com.br/jiu-jitsu',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:jiu-jitsu',
    },
    {
      title: 'Judô',
      url: 'https://www.decathlon.com.br/judo',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:judo',
    },
    {
      title: 'Karatê',
      url: 'https://www.decathlon.com.br/karate',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:karate',
    },
    {
      title: 'Mergulho e snorkeling',
      url: 'https://www.decathlon.com.br/mergulho-e-snorkeling',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:mergulho-e-snorkeling',
    },
    {
      title: 'Mountain bike',
      url: 'https://www.decathlon.com.br/ciclismo-mountain-bike',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:mountain-bike',
    },
    {
      title: 'Musculação',
      url: 'https://www.decathlon.com.br/musculacao',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:musculação',
    },
    {
      title: 'Natação',
      url: 'https://www.decathlon.com.br/natacao',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:natacao',
    },
    {
      title: 'Observação da natureza',
      url: 'https://www.decathlon.com.br/observacao-da-natureza',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:observacao-da-natureza',
    },
    {
      title: 'Padel',
      url: 'https://www.decathlon.com.br/padel',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:padel',
    },
    {
      title: 'Patinetes',
      url: 'https://www.decathlon.com.br/patinetes',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:patinete',
    },
    {
      title: 'Patins',
      url: 'https://www.decathlon.com.br/patins',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:patins',
    },
    {
      title: 'Pesca',
      url: 'https://www.decathlon.com.br/pesca',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:pesca',
    },
    {
      title: 'Petanca',
      url: 'https://www.decathlon.com.br/petanca',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:petanca',
    },
    {
      title: 'Peteca',
      url: 'https://www.decathlon.com.br/peteca',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:peteca',
    },
    {
      title: 'Pilates',
      url: 'https://www.decathlon.com.br/pilates-e-ginastica-adultos',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:pilates',
    },
    {
      title: 'Pipa',
      url: 'https://www.decathlon.com.br/outros-esportes/pipas',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:pipa',
    },
    {
      title: 'Polo aquático',
      url: 'https://www.decathlon.com.br/polo-aquatico',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:polo-aquatico',
    },
    {
      title: 'Rugby',
      url: 'https://www.decathlon.com.br/rugby',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:rugby',
    },
    {
      title: 'Skate',
      url: 'https://www.decathlon.com.br/skateboards',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:skate',
    },
    {
      title: 'Ski e Snowboard',
      url: 'https://www.decathlon.com.br/ski-e-snowboard',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:ski-e-snowboard',
    },
    {
      title: 'Slackline',
      url: 'https://www.decathlon.com.br/outros-esportes/slackline',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:slackline',
    },
    {
      title: 'Society',
      url: 'https://www.decathlon.com.br/society',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:society',
    },
    {
      title: 'Speedball',
      url: 'https://www.decathlon.com.br/speedball',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:speedball',
    },
    {
      title: 'Squash',
      url: 'https://www.decathlon.com.br/squash',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:squash',
    },
    {
      title: 'Stand up paddle',
      url: 'https://www.decathlon.com.br/stand-up-paddle',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:stand-up-paddle',
    },
    {
      title: 'Surf e bodyboard',
      url: 'https://www.decathlon.com.br/surf-e-bodyboard',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:surf-e-bodyboard',
    },
    {
      title: 'Tênnis',
      url: 'https://www.decathlon.com.br/tennis',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:tennis',
    },
    {
      title: 'Tênis de mesa',
      url: 'https://www.decathlon.com.br/tenis-de-mesa-e-ping-pong',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:tenis-de-mesa',
    },
    {
      title: 'Trail running',
      url: 'https://www.decathlon.com.br/trail-running',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:trail-running',
    },
    {
      title: 'Treino cardio',
      url: 'https://www.decathlon.com.br/treino-cardio',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:treino-cardio',
    },
    {
      title: 'Triathlon',
      url: 'https://www.decathlon.com.br/triathlon',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:triathlon',
    },
    {
      title: 'Trilha e Trekking',
      url: 'https://www.decathlon.com.br/trilha-e-trekking',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:trilha-e-trekking',
    },
    {
      title: 'Volei',
      url: 'https://www.decathlon.com.br/volei-de-quadra',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:volei',
    },
    {
      title: 'Vôlei de praia',
      url: 'https://www.decathlon.com.br/volei-de-praia',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:volei-de-praia',
    },
    {
      title: 'Yoga',
      url: 'https://www.decathlon.com.br/yoga',
      typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
      UriListing: 'filter=d:esporte:yoga',
    },
  ],
}
