import { Link } from 'gatsby'
import type { Level2 } from 'src/graphql/queries/menu'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import { IconCategoryHighLight } from './utils/icons'

export const StaticItemSecondLevel = ({
  item,
  department,
  setMenuIsOpen,
}: {
  item: Level2
  department: string
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const handleSendEvent = (position: string, event: string | undefined) => {
    const eventTrack = makeEventTrack({
      eventAction: `${event}${position}`,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
  }

  return (
    <ul className="mt-8 first-of-type:mt-0 last-of-type:pb-6">
      <li
        className={`${
          item.highlight
            ? 'text-restructure-action '
            : 'text-restructure-secondary'
        } mobile-body-semibold-text1 desktop-body-semibold-text1 mb-4 flex items-center gap-2`}
      >
        {item.highlight && <IconCategoryHighLight />}
        {item.title}
      </li>
      {item.subcategories?.map((subcategorie) => {
        return (
          <li key={subcategorie.title} className="mb-4">
            <Link
              className="block mobile-body-regular-text3 desktop-body-regular-text3 text-restructure-secondary"
              to={subcategorie.url ?? ''}
              aria-label={subcategorie.title ?? ''}
              rel="noreferrer"
              onClick={() => {
                handleSendEvent(
                  ' - Menu',
                  `${department} > ${item.title} > ${subcategorie.title}`
                )
                setMenuIsOpen(false)
              }}
            >
              {subcategorie.title}
            </Link>
          </li>
        )
      })}

      {item.url && item.url !== '/#' && (
        <li className={`${item.highlight ? 'hidden' : 'flex'}`}>
          <Link
            className="restructure-small-desktop:block mobile-body-regular-text3-link desktop-body-regular-text3-link text-restructure-action"
            to={item.url ?? ''}
            aria-label={item.title ?? ''}
            rel="noreferrer"
            onClick={() => {
              handleSendEvent(
                ' > Ver tudo - Menu',
                `${department} > ${item.title}`
              )
              setMenuIsOpen(false)
            }}
          >
            Ver tudo
          </Link>
        </li>
      )}
    </ul>
  )
}
