import { useAccountContext } from 'src/components/account/context'

import CashbackBalance from '../../CashbackBalance/CashbackBalance'
import LoggedInContent from './LoggedInContent'
import LoggedOutContent from './LoggedOutContent'

type LoginDropdownProps = {
  className?: string
}

const LoginDropdown = ({ className = '' }: LoginDropdownProps) => {
  const { isLoggedIn } = useAccountContext()

  return (
    <div className={className}>
      <CashbackBalance />

      <div className="px-6 py-4">
        {isLoggedIn ? <LoggedInContent /> : <LoggedOutContent />}
      </div>
    </div>
  )
}

export default LoginDropdown
