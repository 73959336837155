type LogoProps = {
  readonly width?: number
  readonly height?: number
  readonly className?: string
  readonly bgFill?: string
}

function Logo({
  width = 187,
  height = 28,
  className,
  bgFill = 'none',
}: LogoProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 187 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M57.0766 23.8002H70.3206V19.5442H62.1166V15.9742H69.3826V12.0122H62.1166V8.44224H70.3206V4.20025H57.0766V23.8002ZM86.7566 15.3582C84.8386 18.4102 82.9766 19.6842 80.5826 19.6842C77.4746 19.6842 75.6686 17.5002 75.6686 13.7062C75.6686 10.1082 77.3346 8.31624 79.8966 8.31624C81.5906 8.31624 82.9906 9.07224 83.4246 11.5922H88.4646C87.9186 6.79024 84.8526 3.80824 79.9526 3.80824C74.2686 3.80824 70.5306 7.82624 70.5306 13.9862C70.5306 20.1882 74.2686 24.1922 80.4146 24.1922C84.4326 24.1922 87.1626 22.5122 88.9686 20.2442H95.8426V23.8002H100.855V4.20024H93.7426L86.7566 15.3582ZM95.8426 16.3102H91.4046L95.8426 9.10024V16.3102ZM46.3246 4.20024H38.9606V23.8002H46.3246C52.1626 23.8002 55.9286 19.9502 55.9286 14.0002C55.9286 8.05024 52.1626 4.20024 46.3246 4.20024ZM46.2546 19.5442H44.0006V8.44224H46.2546C49.1666 8.44224 50.8046 10.5002 50.8046 14.0002C50.8046 17.4862 49.1666 19.5442 46.2546 19.5442ZM158.703 3.80825C152.781 3.80825 148.805 7.82624 148.805 14.0002C148.805 20.1742 152.781 24.1922 158.703 24.1922C164.639 24.1922 168.601 20.1742 168.601 14.0002C168.601 7.82625 164.639 3.80825 158.703 3.80825ZM158.703 19.6842C155.791 19.6842 153.957 17.7382 153.957 14.0002C153.957 10.2622 155.791 8.31624 158.703 8.31624C161.629 8.31624 163.449 10.2622 163.449 14.0002C163.449 17.7382 161.629 19.6842 158.703 19.6842ZM102.115 8.44224H107.057V23.8002H112.097V8.44224H117.039V4.20025H102.115L102.115 8.44224ZM181.467 4.20025V14.9942L174.971 4.20025H169.749V23.8002H174.621V12.5583L181.383 23.8002H186.339V4.20024L181.467 4.20025ZM141.665 4.20025H136.625V23.8002H149.267V19.5583H141.665V4.20025ZM130.129 11.6762H123.339V4.20025H118.299V23.8002H123.339V15.9042H130.129V23.8002H135.169V4.20024H130.129V11.6762Z"
        fill={bgFill}
      />
      <path
        d="M24.7368 0.000244141C13.7925 0.000244141 0.178833 11.3239 0.178833 20.7087C0.178833 25.5557 3.90186 28.0002 8.81907 28.0002C12.4297 28.0002 16.799 26.6796 21.0138 24.1367V5.40917C19.8898 7.33391 14.6073 15.0891 10.3645 19.2195C8.20092 21.3269 6.48692 22.2401 5.01176 22.2401C3.35395 22.2401 2.5672 21.1161 2.5672 19.4443C2.5672 11.8577 15.3379 1.99523 23.7955 1.99523C27.2797 1.99523 29.5276 3.54064 29.5276 6.54716C29.5276 9.30079 27.659 12.7569 24.4699 15.9461V21.7484C30.0333 17.351 33.363 11.7453 33.363 7.22151C33.363 2.45885 29.654 0.000244141 24.7368 0.000244141Z"
        fill={bgFill}
      />
    </svg>
  )
}

export default Logo
