export const brandsMenuData = {
  title: 'Marcas',
  url: '/#',
  typeDestinySectionLevel01: 'abrir-listagem',
  UriSectionLevel01: '',
  imageBannerDesktop: '/arquivos/image-empty-280x360.png',
  imageBannerMobile: '/arquivos/image-empty-337x190.png',
  linkBanner: '/#',
  typeDestinyBannerLevel01: 'abrir-listagem',
  UriBannerLevel01: '',
  iconAPP:
    'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/1266699c-0d04-4d1d-9a51-a7898e92cee9___204cbb13e6dcdaa236b8fe36c7d1605b.png',
  categories: [
    {
      title: 'Marcas Decathlon',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Mais Vendidos',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/8fcb54ca-0056-4a45-b1ef-22466c69ef19___d61c2ebf14bdc8330700c2563ef4cd12.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Kalenji',
          url: 'https://www.decathlon.com.br/kalenji',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:kalenji&sort=relevance',
        },
        {
          title: 'Forclaz',
          url: 'https://www.decathlon.com.br/forclaz',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:forclaz&sort=relevance',
        },
        {
          title: 'Domyos',
          url: 'https://www.decathlon.com.br/domyos',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:domyos&sort=relevance',
        },
        {
          title: 'Olaian',
          url: 'https://www.decathlon.com.br/olaian',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:olaian&sort=relevance',
        },
        {
          title: 'Sandever',
          url: 'https://www.decathlon.com.br/sandever',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:sandever&sort=relevance',
        },
        {
          title: 'Kipsta',
          url: 'https://www.decathlon.com.br/kipsta',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:kipsta&sort=relevance',
        },
        {
          title: 'Tarmak',
          url: 'https://www.decathlon.com.br/tarmak',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:tarmak&sort=relevance',
        },
        {
          title: 'Nabaiji',
          url: 'https://www.decathlon.com.br/nabaiji',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:nabaiji&sort=relevance',
        },
        {
          title: 'Rockrider',
          url: 'https://www.decathlon.com.br/rockrider',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:rockrider&sort=relevance',
        },
        {
          title: 'Vanrysel',
          url: 'https://www.decathlon.com.br/pesquisa?q=vanrysel',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:van-rysel&sort=relevance',
        },
      ],
    },
    {
      title: 'Marcas Parceiras',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02:
        'c={id_dept}/{id_catg}/{id_scatg}/&filter=d:genero:{value}',
      picto: 'Apoio',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/0acf93b9-efaf-4877-b772-7ebe262e15fe___356faf0369b96c41bf072660d5d0ad1e.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Integralmédica',
          url: 'https://www.decathlon.com.br/pesquisa?q=Integralm%C3%A9dica&sort=score_desc&page=0',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:integralmedica&sort=relevance',
        },
        {
          title: 'Black Skull',
          url: 'https://www.decathlon.com.br/pesquisa?q=Black+Skull&sort=score_desc&page=0',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:blackskull&sort=relevance',
        },
        {
          title: 'Mormaii',
          url: 'https://www.decathlon.com.br/pesquisa?q=Mormaii&sort=score_desc&page=0',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:mormaii&sort=relevance',
        },
        {
          title: 'Dux Nutrition',
          url: 'https://www.decathlon.com.br/pesquisa?q=Dux+Nutrition&sort=score_desc&page=0',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:duxnutrition&sort=relevance',
        },
        {
          title: 'Umbro',
          url: 'https://www.decathlon.com.br/pesquisa?q=Umbro&sort=score_desc&page=0',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:umbro&sort=relevance',
        },
        {
          title: 'Atrio',
          url: 'https://www.decathlon.com.br/pesquisa?q=Atrio&sort=score_desc&page=0',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:atrio&sort=relevance',
        },
        {
          title: 'Kikos',
          url: 'https://www.decathlon.com.br/pesquisa?q=Kikos&sort=score_desc&page=0',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:kikos&sort=relevance',
        },
        {
          title: 'Gallant',
          url: 'https://www.decathlon.com.br/pesquisa?q=Gallant&sort=score_desc&page=0',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:gallant&sort=relevance',
        },
        {
          title: 'Caloi',
          url: 'https://www.decathlon.com.br/pesquisa?q=Caloi&sort=score_desc&page=0',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:caloi&sort=relevance',
        },
        {
          title: 'Sense',
          url: 'https://www.decathlon.com.br/pesquisa?q=Sense&sort=score_desc&page=0',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'filter=d:brand:sense&sort=relevance',
        },
      ],
    },
  ],
  includeModule: true,
}
