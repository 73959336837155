import type { Event } from './types/event'

export const sendEvent = (event: Event) => {
  try {
    if (typeof window !== 'undefined' && typeof document === 'object') {
      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push(event)
    }
  } catch {
    throw new Error('Object for dataLayer is undefined.')
  }
}
