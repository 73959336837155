import { useCallback } from 'react'

import type { LinxMetaOther } from '../types'
import { useLinxEventDispatch } from './useLinxEventDispatch'

export const useLinxOtherView = () => {
  const { dispatchLinxEvent } = useLinxEventDispatch()

  /**
   * Checks whether the current page is a known page or an unknown page
   * known pages already have their own event configured
   */
  const isAMappedPage = (pathPage: string) => {
    const listOfMappedPages = [
      '/',
      '/*slug',
      '/:slug/p',
      '/pesquisa',
      '/pesquisa/',
      '/404',
      '/404/',
      '/checkout/cart/',
    ]

    if (pathPage.includes('/account')) {
      return true
    }

    return listOfMappedPages.includes(pathPage)
  }

  const sendOtherViewEvent = useCallback(
    (pathPage: string) => {
      if (isAMappedPage(pathPage) === true) {
        return
      }

      const linxMetaOther: LinxMetaOther = {
        page: 'other',
      }

      return dispatchLinxEvent(linxMetaOther)
    },
    [dispatchLinxEvent]
  )

  return { sendOtherViewEvent }
}
