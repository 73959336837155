import type { Product } from 'src/components/product/types'

function checkSufix(link: string, isKit: boolean) {
  if (!link) {
    return ''
  }

  return isKit ? `/${link}/kit` : `/${link}/p`
}

export function transformProductSuggestions(products: Product[]) {
  return products.map((product) => ({
    clickUrl: product.clickUrl ?? '',
    imageUrl: product.items[0].images[0].imageUrl,
    name: product.productName,
    offer: product.items[0].offer,
    productId: product.productId,
    slug: product?.link
      ? product?.link
      : checkSufix(product?.linkText, product.items[0].isKit),
  }))
}
