export function Headphone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="headphones">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.75C9.81196 3.75 7.71354 4.61919 6.16637 6.16637C4.61919 7.71354 3.75 9.81196 3.75 12V18C3.75 18.4142 3.41421 18.75 3 18.75C2.58579 18.75 2.25 18.4142 2.25 18V12C2.25 9.41414 3.27723 6.93419 5.10571 5.10571C6.93419 3.27723 9.41414 2.25 12 2.25C14.5859 2.25 17.0658 3.27723 18.8943 5.10571C20.7228 6.93419 21.75 9.41414 21.75 12V18C21.75 18.4142 21.4142 18.75 21 18.75C20.5858 18.75 20.25 18.4142 20.25 18V12C20.25 9.81196 19.3808 7.71354 17.8336 6.16637C16.2865 4.61919 14.188 3.75 12 3.75Z"
          fill="#3643BA"
        />
        <path
          id="Vector (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 14C2.25 13.5858 2.58579 13.25 3 13.25H6C6.72935 13.25 7.42882 13.5397 7.94454 14.0555C8.46027 14.5712 8.75 15.2707 8.75 16V19C8.75 19.7293 8.46027 20.4288 7.94454 20.9445C7.42882 21.4603 6.72935 21.75 6 21.75H5C4.27065 21.75 3.57118 21.4603 3.05546 20.9445C2.53973 20.4288 2.25 19.7293 2.25 19V14ZM3.75 14.75V19C3.75 19.3315 3.8817 19.6495 4.11612 19.8839C4.35054 20.1183 4.66848 20.25 5 20.25H6C6.33152 20.25 6.64946 20.1183 6.88388 19.8839C7.1183 19.6495 7.25 19.3315 7.25 19V16C7.25 15.6685 7.1183 15.3505 6.88388 15.1161C6.64946 14.8817 6.33152 14.75 6 14.75H3.75ZM18 14.75C17.6685 14.75 17.3505 14.8817 17.1161 15.1161C16.8817 15.3505 16.75 15.6685 16.75 16V19C16.75 19.3315 16.8817 19.6495 17.1161 19.8839C17.3505 20.1183 17.6685 20.25 18 20.25H19C19.3315 20.25 19.6495 20.1183 19.8839 19.8839C20.1183 19.6495 20.25 19.3315 20.25 19V14.75H18ZM16.0555 14.0555C16.5712 13.5397 17.2707 13.25 18 13.25H21C21.4142 13.25 21.75 13.5858 21.75 14V19C21.75 19.7293 21.4603 20.4288 20.9445 20.9445C20.4288 21.4603 19.7293 21.75 19 21.75H18C17.2707 21.75 16.5712 21.4603 16.0555 20.9445C15.5397 20.4288 15.25 19.7293 15.25 19V16C15.25 15.2707 15.5397 14.5712 16.0555 14.0555Z"
          fill="#3643BA"
        />
      </g>
    </svg>
  )
}
