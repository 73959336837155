import { sportsMenuData } from './sportsMenuData'
import { womenMenuData } from './womenMenuData'
import { menMenuData } from './menMenuData'
import { kidsMenuData } from './kidsMenuData'
import { accessoriesMenuData } from './accessoriesMenuData'
import { equipmentMenuData } from './equipmentMenuData'
import { brandsMenuData } from './brandsMenuData'
import { salesMenuData } from './salesMenuData'

export const menuDataFallback = {
  departments: [
    sportsMenuData,
    womenMenuData,
    menMenuData,
    kidsMenuData,
    accessoriesMenuData,
    equipmentMenuData,
    brandsMenuData,
    salesMenuData,
  ],
}
