import type { PropsWithChildren, ReactNode } from 'react'
import { useState, createContext, useContext } from 'react'

type ModalSheetProviderProps = PropsWithChildren

interface ModalSheetContextValue {
  isModalOpen: boolean
  setModalConfig: React.Dispatch<React.SetStateAction<ModalSheetConfig>>
  modalConfig: ModalSheetConfig
  openModal: (config: ModalSheetConfig) => void
  closeModal: () => void
}

export interface ModalSheetConfig {
  content: ReactNode
  height?: string
  title?: string
  position?: 'bottom' | 'left' | 'right'
}

const ModalSheetContext = createContext<ModalSheetContextValue>(
  {} as ModalSheetContextValue
)

export const ModalSheetProvider = ({ children }: ModalSheetProviderProps) => {
  const defaultConfig: ModalSheetConfig = {
    content: <></>,
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalConfig, setModalConfig] =
    useState<ModalSheetConfig>(defaultConfig)

  const openModal = (config: ModalSheetConfig) => {
    setModalConfig(config)
    setIsModalOpen(true)

    if (typeof document === 'undefined' || !document?.body?.style) {
      return
    }

    document.body.style.overflow = 'hidden'
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setModalConfig(defaultConfig)

    if (typeof document === 'undefined' || !document?.body?.style) {
      return
    }

    document.body.style.overflow = 'auto'
  }

  const value = {
    isModalOpen,
    setModalConfig,
    modalConfig,
    openModal,
    closeModal,
  }

  return (
    <ModalSheetContext.Provider value={value}>
      {children}
    </ModalSheetContext.Provider>
  )
}

export const useModalSheet = () => {
  const context = useContext(ModalSheetContext)

  if (context === undefined) {
    throw new Error('Do not use Modal outside the Modal context.')
  }

  return context
}
