import axios from 'axios'

import { OrderForm } from '../../entity/orderForm'
import type { ClientProfileBody } from '../types'

export const addCartProfile = async (
  orderFormId: string,
  clientProfileData: ClientProfileBody
) => {
  const { data } = await axios.post(
    `/api/checkout/orderForm/${orderFormId}/attachments/clientProfileData`,
    {
      ...clientProfileData,
    },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  )

  return new OrderForm(data)
}

export const removeCartProfile = async (orderFormId: string) => {
  const { data } = await axios.get(
    `/api/checkout/changeToAnonymousUser/${orderFormId}`,
    {
      headers: { 'Content-Type': 'application/json' },
    }
  )

  return new OrderForm(data)
}
