import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { useMobile } from 'src/hooks/useMobile'
import { useLocation } from '@reach/router'

import PopUpAppsflyer from './PopUpAppsflyer'
import PopUpCookies from './PopUpCookies'

const InitialPopUps = ({ isApp = false }: { isApp?: boolean }) => {
  const isB2B = checkEnviromentIsB2B()
  const { screenWidth } = useMobile()
  const { pathname } = useLocation()

  const hidePopUps = ['cart'].some((item) => pathname.includes(item))

  if (hidePopUps) {
    return <></>
  }

  return (
    <>
      {!isApp && screenWidth <= 1024 && isB2B === false && <PopUpAppsflyer />}

      {!isApp && <PopUpCookies />}
    </>
  )
}

export default InitialPopUps
