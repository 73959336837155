export const equipmentMenuData = {
  title: 'Equipamentos',
  url: '/#',
  typeDestinySectionLevel01: 'abrir-listagem',
  UriSectionLevel01: '',
  imageBannerDesktop: '/arquivos/image-empty-280x360.png',
  imageBannerMobile: '/arquivos/image-empty-337x190.png',
  linkBanner: '/#',
  typeDestinyBannerLevel01: 'abrir-listagem',
  UriBannerLevel01: '',
  iconAPP:
    'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/550c9df5-7652-419b-ba17-2f91cc856b56___bff7ee775d2388c0919c367fd8ed021c.png',
  categories: [
    {
      title: 'Equipamentos',
      url: '/#',
      typeDestinySectionLevel02: 'abrir-listagem',
      UriSectionLevel02: '',
      picto: 'Mais Vendidos',
      pictoAPP:
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/bdd30485-1a2f-4374-8ea3-b0f06ce57fe4___8149bb7ed604f50ef7c7563808c0db2f.png',
      typeThirtLevel: '3.5 Listagem simples',
      subcategories: [
        {
          title: 'Aparelhos de musculação',
          url: '/pesquisa/?q=Aparelhos de musculação',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371770/',
        },
        {
          title: 'Barracas, gazebos e Abrigos',
          url: '/pesquisa/?q=Barracas, gazebos e Abrigos',
          typeDestinyListing: 'destino-final-plp-search-filter-vtex-via-bff',
          UriListing: 'c=72371527/72371727/',
        },
        {
          title: 'Bicicletas ',
          url: '/pesquisa/?q=Bicicletas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371737/',
        },
        {
          title: 'Bikes, esteiras e Elípticos',
          url: '/pesquisa/?q=Bikes, esteiras e Elípticos',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371741/',
        },
        {
          title: 'Bolas',
          url: '/pesquisa/?q=Bolas',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371747/',
        },
        {
          title: 'Caiaques',
          url: '/pesquisa/?q=Caiaques ',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371764/',
        },
        {
          title: 'Elásticos, Minibands e Acessórios',
          url: '/pesquisa/?q=Elásticos, Minibands e Acessórios',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/[catg]/',
        },
        {
          title: 'Mesas de ping pong',
          url: '/pesquisa/?q=Mesas de ping pong',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371728/',
        },
        {
          title: 'Mochilas e Bagagens',
          url: '/pesquisa/?q=Mochilas e Bagagens',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371677/',
        },
        {
          title: 'Patins',
          url: '/pesquisa/?q=Patins, Patinetes e Skates',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371749/',
        },
        {
          title: 'Pranchas de surf',
          url: '/pesquisa/?q=Pranchas de surf',
          typeDestinyListing: 'destino-final-plp-category-vtex-via-bff',
          UriListing: 'c=72371527/72371744/',
        },
      ],
    },
  ],
  includeModule: true,
}
